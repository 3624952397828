import React from "react";
import { useState, useEffect } from "react";
import Signature from "../../../hooks/Web3Connection/userSign";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/Web3Connection/useAuth";
// import { useTheme } from "@/utils/themecontext/ThemeProvider";
import { ErrorToast, SuccessToast } from "../../toast/Toast";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../services/client";
import { Modal } from "react-bootstrap";
import "./Forgetpass.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../../services/redux/middleware/signin";

export default function ForgetPass(props) {
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const formValidation = () => {
    if (!email) {
      ErrorToast("Please Enter Email");
      return false;
    }
  };
  async function forget() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        email:email
      };
      dispatch(forgetPassword(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);          
         
          SuccessToast("Email sent")
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-whole-forget"
      show={props.show}
      // onHide={() => props.setModalShows(false)}
    >
      <Modal.Header
        style={{ background: "white", color: "black", maxwidth: "680px" }}
        className="main-section-wallet-forget"
      >
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        {/* <img
          src={"/Images/SignIn/Vector.svg"}
          className="close-btn-img"
          onClick={() => props.setModalShows(false)}
        />
        <p  onClick={() => props.setModalShows(false)} >Back</p> */}
      </Modal.Header>
      <Modal.Body
        style={{
          background: "white",

          borderTop: "none",
          color: "black",
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
        className="main-section-wallet"
      >
        <div className="forgetpass">
          <div className="forgetpass__main">
            <h1>Forgot Password</h1>
            <p>Seems like you are having problem to login.</p>
            <div className="forgotpass__email">
              <div className="email-label-contain">
                <h5>Email address</h5>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="shadow-btn"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <button onClick={()=>{forget()}}>Verify</button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
