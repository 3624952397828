import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState, useEffect } from "react";
import LineChartShadow from "./LineChartShadow";
import { Data, Data2, Data3 } from "./DataLine";

Chart.register(CategoryScale);
Chart.defaults.font.size = 10;
Chart.defaults.font.family = "Montserrat";
Chart.defaults.font.color = "#667085";

export default function LineChartMain({ chartFilter }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Gain",
        data: [],
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointHoverBackgroundColor: "#0F68FF",
        pointHoverBorderColor: "#fff",
        pointRadius: 4,
        pointHoverRadius: 4,
        fill: false,
        borderColor: "#0F68FF",
        tension: 0.4,
      },
    ],
  });

  useEffect(() => {
    let cucumlative;

    // Update chart data based on chartFilter
    setChartData({
      labels:
        chartFilter === "Yearly"
          ? Data2?.map((item) => item.year)
          : chartFilter === "Monthly"
          ? Data3?.map((item) => item.year)
          : Data?.map((item) => item.year),
      datasets: [
        {
          label: "Total Gain",
          data:
            chartFilter === "Yearly"
              ? Data2.map((item, index) => {
                  return item?.userGain;
                })
              : chartFilter === "Monthly"
              ? Data3.map((item, index) => {
                  return item?.userGain;
                })
              : Data.map((item, index) => {
                  return item?.userGain;
                }),
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "#0F68FF",
          pointHoverBorderColor: "#fff",
          pointRadius: 4,
          pointHoverRadius: 4,
          fill: false,
          borderColor: "#0F68FF",
          tension: 0.4,
        },
      ],
    });
  }, [chartFilter]); // Re-run effect when chartFilter changes

  return (
    <div className="App">
      <LineChartShadow chartData={chartData} chartFilter={chartFilter} />
    </div>
  );
}
