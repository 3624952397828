import React, { useState } from "react";

import "./forget.css";
import ForgetPass from "../../../Components/modal/Forgetpass/Forgetpass";
export default function Forget() {
  const [shows, setModalsShow] = useState(true);

  return (
    <>
      <div className="SignUp-PG">
        <ForgetPass
          show={shows}
          setModalShows={setModalsShow}
         
        />
      </div>
    </>
  );
}
