import contractAbi from "../../utils/Contracts/contractAbi.json";
import { MintContract } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";
export const useWhiteList = () => {
  const whiteListReadHook = useCallback(async (customweb3, Id,account) => {
    const contract = new customweb3.eth.Contract(contractAbi, MintContract);
    try {
      const estimatedGas = await contract.methods.whitelist(account).estimateGas();

      const details = await contract.methods.whitelist(account).call({
        gas: estimatedGas,
      });
      console.log("this is whitelist",details)

      return details;
    } catch (error) {
      console.log(error, "errpr");
      throw error;
    }
  });
  return { whiteListReadHook: whiteListReadHook };
};
export default useWhiteList;

