import React from "react";
import Lottie from "lottie-react";
import animationData from "./animation_lnailmmc.json";

function TableLoader({ text }) {
  return (
    <Lottie
      animationData={animationData}
      loop={true}
      autoplay={true}
      style={{ width: "100%" }}
    />
  );
}

export default TableLoader;
