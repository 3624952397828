import React from "react";
import "./listedNFT.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ListedNFT = () => {
  const [selectedItem, setSelectedItem] = useState("");
  const navigate = useNavigate();
  return (
    <div className="ListedNFT__main">
      <div className="ListedNFT__main-top">
        <div className="search-input_div">
          <input
            type="text"
            placeholder="Search..."
            className="search-input-listed"
            onChange={(e) => e.target.value}
          />
          <img
            src="/Images/search-input.svg"
            alt="/"
            className="search-input__img"
          />
        </div>
        <div className="ListedNFT__main-top-dropdown">
          <div>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic_listed" className="dropdown">
                Status
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: "106px" }}>
                <Dropdown.Item
                  onClick={() => {
                    setSelectedItem("All");
                  }}
                  style={{ background: "transparent" }}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSelectedItem("Listed");
                  }}
                  style={{ background: "transparent" }}
                >
                  Listed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSelectedItem("New");
                  }}
                  style={{ background: "transparent" }}
                >
                  New
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic-listed-2"
                className="dropdown"
              >
                Price
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "203px" }}>
                <Dropdown.Item
                  style={{ pointerEvents: "none", background: "transparent" }}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic-listed-body"
                      className="dropdown"
                      onClick={(e) => e.stopPropagation()}
                      style={{ pointerEvents: "auto", color: "#667085" }}
                    >
                      ETH
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "170px" }}>
                      <Dropdown.Item></Dropdown.Item>
                      <Dropdown.Item>Listed</Dropdown.Item>
                      <Dropdown.Item>New</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ pointerEvents: "none", background: "transparent" }}
                >
                  <div
                    className="dropdown-listed-body-box"
                    style={{ pointerEvents: "auto" }}
                  >
                    <input
                      type="number"
                      placeholder="Min"
                      onClick={(e) => e.stopPropagation()}
                    />
                    <p className="dropdown-listed-body-box-text">to</p>
                    <input
                      type="number"
                      placeholder="Max"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </Dropdown.Item>
                <Dropdown.Item style={{ background: "transparent" }}>
                  <button className="dropdown-listed-body-box-btn">
                    Apply
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="ListedNFT__main-hero">
        <div className="ListedNFT__main-hero-left">
          <h1>Listed Web3Fund NFTs </h1>
          <div className="ListedNFT__main-hero-txt">
            <div className="ListedNFT__main-hero-txt_body">
              <p className="ListedNFT__main-hero-txt_head">
                394 <span>ETH</span>
              </p>
              <p className="ListedNFT__main-hero-txt_text">Total volume</p>
            </div>
            <div>
              <p className="ListedNFT__main-hero-txt_head">
                0.8 <span>ETH</span>
              </p>
              <p className="ListedNFT__main-hero-txt_text">Floor Price</p>
            </div>
            <div>
              <p className="ListedNFT__main-hero-txt_head">
                14% <span>ETH</span>
              </p>
              <p className="ListedNFT__main-hero-txt_text">Listed</p>
            </div>
            <div>
              <p className="ListedNFT__main-hero-txt_head">
                200(20%) <span>ETH</span>
              </p>
              <p className="ListedNFT__main-hero-txt_text">Owners</p>
            </div>
          </div>
        </div>
        <div className="ListedNFT__main-hero-right">
          <img src="/Images/ListedNFT/Img1.svg" alt="img" />
        </div>
      </div>
      <div className="ListedNFT__main-body">
        <div className="ListedNFT__main-cards"   >
          <img src={"/Images/Dashboard/Cards-2.svg"} alt="/" />
          <div className="ClaimProfits__main-cards-hero">
            <div className="ClaimProfits__main-cards-heading">
              <h6>M5-24 20k</h6>
              <p className="ListedNFT__main-body-txt">
                TOKEN ID: <span>0</span>
              </p>
            </div>
            <div className="ListedNFT__cards-body">
              <p>
                PRICE: <span>0.8 ETH</span>
              </p>
            </div>
          </div>
          <div className="Listed__main-cards-btn">
            <button onClick={() => {navigate('/ListedNFTnext')}}>Purchase</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListedNFT;
