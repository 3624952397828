import React, { useState } from "react";

import "./password.css";
import Newpass from "../../../Components/modal/Newpassword/Newpass";
export default function NewPassword() {
  const [shows, setModalsShow] = useState(true);

  return (
    <>
      <div className="SignUp-PG">
        <Newpass show={shows} setModalShows={setModalsShow} />
      </div>
    </>
  );
}
