import React from 'react';
import "./verification.css";
import Verificationmodal from '../../Components/modal/Verification/Verificationmodal';


const Verification = () => {
  return (
    <div className='Verification__main'>
        <img className='verification__logo' src='/Images/Signin/moralis 3.svg' alt='/' />
        <Verificationmodal />
    </div>
  )
}

export default Verification