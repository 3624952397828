export const Data = [
  {
    id: 1,
    year: "Q1 17",
    userGain: 54.36,
  },
  {
    id: 2,
    year: "Q2 17",
    userGain: 127.53,
  },
  {
    id: 3,
    year: "Q3 17",
    userGain: 141.74,
  },
  {
    id: 4,
    year: "Q4 17",
    userGain: 189.26,
  },
  {
    id: 5,
    year: "Q1 18",
    userGain: 165.94,
  },
  {
    id: 6,
    year: "Q2 18",
    userGain: 185.66,
  },
  {
    id: 7,
    year: "Q3 18",
    userGain: 146.51,
  },
  {
    id: 8,
    year: "Q4 18",
    userGain: 122.48,
  },
  {
    id: 9,
    year: "Q1 19",
    userGain: 127.89,
  },
  {
    id: 10,
    year: "Q2 19",
    userGain: 178.28,
  },
  {
    id: 11,
    year: "Q3 19",
    userGain: 150.67,
  },
  {
    id: 12,
    year: "Q4 19",
    userGain: 131.47,
  },
  {
    id: 13,
    year: "Q1 20",
    userGain: 145.9,
  },
  {
    id: 14,
    year: "Q2 20",
    userGain: 220.91,
  },
  {
    id: 15,
    year: "Q3 20",
    userGain: 310.38,
  },
  {
    id: 16,
    year: "Q4 20",
    userGain: 486.01,
  },
  {
    id: 17,
    year: "Q1 21",
    userGain: 855.89,
  },
  {
    id: 18,
    year: "Q2 21",
    userGain: 954.15,
  },
  {
    id: 19,
    year: "Q3 21",
    userGain: 1113.44,
  },
  {
    id: 20,
    year: "Q4 21",
    userGain: 1262.35,
  },
  {
    id: 20,
    year: "Q1 22",
    userGain: 1212.52,
  },
  {
    id: 20,
    year: "Q2 22",
    userGain: 738.58,
  },
  {
    id: 20,
    year: "Q3 22",
    userGain: 852.14,
  },
  {
    id: 20,
    year: "Q4 22",
    userGain: 816.09,
  },
  {
    id: 20,
    year: "Q1 23",
    userGain: 1021.87,
  },
  {
    id: 20,
    year: "Q2 23",
    userGain: 1053.51,
  },
  {
    id: 20,
    year: "Q3 23",
    userGain: 981.54,
  },
  {
    id: 20,
    year: "Q4 3",
    userGain: 1151.97,
  },
  {
    id: 21,
    year: "Q1 24",
    userGain: 1478.66,
  },
  {
    id: 22,
    year: "Q2 24",
    userGain: 1159.49,
  },
];
export const Data2 = [
  {
    
    id: 0,
    year: "2016",
    userGain: 0,
    userGainValue: 135610,
  },
  {

    id: 1,
    year: "2017",
    userGain: 189.26,
    userGainValue: 135610,
  },
  {
    id: 2,
    year: "2018",
    userGain: 122.48,
    userGainValue: 217488.36,
  },
  {
    id: 3,
    year: "2019",
    userGain: 131.47,
    userGainValue: 387727.67,
  },
  {
    id: 4,
    year: "2020",
    userGain: 486.01,
    userGainValue: 758481.37,
  },
  {
    id: 5,
    year: "2021",
    userGain: 1262.35,
    userGainValue: 1564556.74,
  },
  {
    id: 5,
    year: "2022",
    userGain: 816.09,
    userGainValue: 1236413.1,
  },
  {
    id: 6,
    year: "2023",
    userGain: 1151.97,
    userGainValue: 1447980.65,
  },
  {
    id: 7,
    year: "2024",
    userGain: 1159.49,
    userGainValue: 1447980.65,
  },
];

export const Data3 = [
  {
    id: 1,
    year: "01/01/2017",
    userGain: 5.32,
    userGainValue: 105320.0,
  },
  {
    id: 2,
    year: "01/02/2017",
    userGain: 13.76,
    userGainValue: 113761.4,
  },
  {
    id: 3,
    year: "01/03/2017",
    userGain: 54.36,
    userGainValue: 154357.15,
  },
  {
    id: 4,
    year: "01/04/2017",
    userGain: 71.79,
    userGainValue: 171791.79,
  },
  {
    id: 5,
    year: "01/05/2017",
    userGain: 118.01,
    userGainValue: 218012.38,
  },
  {
    id: 6,
    year: "01/06/2017",
    userGain: 127.53,
    userGainValue: 227528.62,
  },
  {
    id: 7,
    year: "01/07/2017",
    userGain: 117.18,
    userGainValue: 217179.86,
  },
  {
    id: 8,
    year: "01/08/2017",
    userGain: 150.79,
    userGainValue: 250792.06,
  },
  {
    id: 9,
    year: "01/09/2017",
    userGain: 141.74,
    userGainValue: 241742.64,
  },
  {
    id: 10,
    year: "01/10/2017",
    userGain: 141.77,
    userGainValue: 241774.88,
  },
  {
    id: 11,
    year: "01/11/2017",
    userGain: 158.83,
    userGainValue: 258828.06,
  },
  {
    id: 12,
    year: "01/12/2017",
    userGain: 189.26,
    userGainValue: 289257.62,
  },
  {
    id: 13,
    year: "01/01/2018",
    userGain: 226.87,
    userGainValue: 326868.34,
  },
  {
    id: 14,
    year: "01/02/2018",
    userGain: 207.26,
    userGainValue: 307256.24,
  },
  {
    id: 15,
    year: "01/03/2018",
    userGain: 165.94,
    userGainValue: 265937.96,
  },
  {
    id: 16,
    year: "01/04/2018",
    userGain: 212.67,
    userGainValue: 312669.9,
  },
  {
    id: 17,
    year: "01/05/2018",
    userGain: 201.76,
    userGainValue: 301757.72,
  },
  {
    id: 18,
    year: "01/06/2018",
    userGain: 185.66,
    userGainValue: 285658.95,
  },
  {
    id: 19,
    year: "01/07/2018",
    userGain: 182.22,
    userGainValue: 282216.76,
  },
  {
    id: 20,
    year: "01/08/2018",
    userGain: 157.67,
    userGainValue: 257670.96,
  },
  {
    id: 21,
    year: "01/09/2018",
    userGain: 146.51,
    userGainValue: 246513.8,
  },
  {
    id: 22,
    year: "01/10/2018",
    userGain: 137.45,
    userGainValue: 237448.26,
  },
  {
    id: 23,
    year: "01/11/2018",
    userGain: 112.05,
    userGainValue: 212047.23,
  },
  {
    id: 24,
    year: "01/12/2018",
    userGain: 122.48,
    userGainValue: 222479.96,
  },
  {
    id: 25,
    year: "01/01/2019",
    userGain: 111.23,
    userGainValue: 211228.03,
  },
  {
    id: 26,
    year: "01/02/2019",
    userGain: 125.6,
    userGainValue: 225602.1,
  },
  {
    id: 27,
    year: "01/03/2019",
    userGain: 127.89,
    userGainValue: 227886.32,
  },
  {
    id: 28,
    year: "01/04/2019",
    userGain: 138.47,
    userGainValue: 238471.64,
  },
  {
    id: 29,
    year: "01/05/2019",
    userGain: 172.25,
    userGainValue: 272245.19,
  },
  {
    id: 30,
    year: "01/06/2019",
    userGain: 178.28,
    userGainValue: 278275.42,
  },
  {
    id: 31,
    year: "01/07/2019",
    userGain: 161.04,
    userGainValue: 261043.21,
  },
  {
    id: 32,
    year: "01/08/2019",
    userGain: 147.14,
    userGainValue: 247136.13,
  },
  {
    id: 33,
    year: "01/09/2019",
    userGain: 150.67,
    userGainValue: 250670.18,
  },
  {
    id: 34,
    year: "01/10/2019",
    userGain: 151,
    userGainValue: 251002.32,
  },
  {
    id: 35,
    year: "01/11/2019",
    userGain: 140.54,
    userGainValue: 240541.8,
  },
  {
    id: 36,
    year: "01/12/2019",
    userGain: 131.47,
    userGainValue: 231467.36,
  },
  {
    id: 37,
    year: "01/01/2020",
    userGain: 172.79,
    userGainValue: 272794.8,
  },
  {
    id: 38,
    year: "01/02/2020",
    userGain: 198.85,
    userGainValue: 298846.71,
  },
  {
    id: 39,
    year: "01/03/2020",
    userGain: 145.9,
    userGainValue: 245896.5,
  },
  {
    id: 40,
    year: "01/04/2020",
    userGain: 207.41,
    userGainValue: 307415.34,
  },
  {
    id: 41,
    year: "01/05/2020",
    userGain: 225.48,
    userGainValue: 324476.89,
  },
  {
    id: 42,
    year: "01/06/2020",
    userGain: 220.91,
    userGainValue: 320907.64,
  },
  {
    id: 43,
    year: "01/07/2020",
    userGain: 299,
    userGainValue: 399004.89,
  },
  {
    id: 44,
    year: "01/08/2020",
    userGain: 344.93,
    userGainValue: 444926.73,
  },
  {
    id: 45,
    year: "01/09/2020",
    userGain: 310.38,
    userGainValue: 410384.24,
  },
  {
    id: 46,
    year: "01/10/2020",
    userGain: 323.85,
    userGainValue: 423852.3,
  },
  {
    id: 47,
    year: "01/11/2020",
    userGain: 438.39,
    userGainValue: 538388.75,
  },
  {
    id: 48,
    year: "01/12/2020",
    userGain: 486.01,
    userGainValue: 586011.69,
  },
  {
    id: 49,
    year: "01/01/2021",
    userGain: 695.14,
    userGainValue: 795137.95,
  },
  {
    id: 50,
    year: "01/02/2021",
    userGain: 725.53,
    userGainValue: 825533.9,
  },
  {
    id: 51,
    year: "01/03/2021",
    userGain: 855.89,
    userGainValue: 955893.21,
  },
  {
    id: 52,
    year: "01/04/2021",
    userGain:  1048.33,
    userGainValue: 1148331.89,
  },
  {
    id: 53,
    year: "01/05/2021",
    userGain: 1036.27,
    userGainValue: 1136274.41,
  },
  {
    id: 54,
    year: "01/06/2021",
    userGain: 954.15,
    userGainValue: 1054152.76,
  },
  {
    id: 55,
    year: "01/07/2021",
    userGain: 1007.53,
    userGainValue: 1107531.22,
  },
  {
    id: 56,
    year: "01/08/2021",
    userGain: 1186.85,
    userGainValue: 1286850.59,
  },
  {
    id: 57,
    year: "01/09/2021",
    userGain: 1113.44,
    userGainValue: 1213441.62,
  },
  {
    id: 58,
    year: "01/10/2021",
    userGain: 1350.17,
    userGainValue: 1450173.04,
  },
  {
    id: 59,
    year: "01/11/2021",
    userGain: 1403.17,
    userGainValue: 1503170.28,
  },
  {
    id: 60,
    year: "01/12/2021",
    userGain: 1262.35,
    userGainValue: 1362350.55,
  },
  {
    id: 61,
    year: "01/01/2022",
    userGain: 1095.83,
    userGainValue: 1195834.16,
  },
  {
    id: 62,
    year: "01/02/2022",
    userGain: 1143.56,
    userGainValue: 1243558.81,
  },
  {
    id: 63,
    year: "01/03/2022",
    userGain: 1212.52,
    userGainValue: 1312519.8,
  },
  {
    id: 64,
    year: "01/04/2022",
    userGain: 1111.81,
    userGainValue: 1211813.74,
  },
  {
    id: 65,
    year: "01/05/2022",
    userGain: 952.96,
    userGainValue: 1052955.97,
  },
  {
    id: 66,
    year: "01/06/2022",
    userGain: 738.58,
    userGainValue: 838583.71,
  },
  {
    id: 67,
    year: "01/07/2022",
    userGain: 954.4,
    userGainValue: 1054404.66,
  },
  {
    id: 68,
    year: "01/08/2022",
    userGain: 919.27,
    userGainValue: 1019273.82,
  },
  {
    id: 69,
    year: "01/09/2022",
    userGain: 852.14,
    userGainValue: 952140.74,
  },
  {
    id: 70,
    year: "01/10/2022",
    userGain: 931.73,
    userGainValue: 1031731.05,
  },
  {
    id: 71,
    year: "01/11/2022",
    userGain: 848.86,
    userGainValue: 948864.28,
  },
  {
    id: 72,
    year: "01/12/2022",
    userGain: 816.09,
    userGainValue: 916085.34,
  },
  {
    id: 73,
    year: "01/01/2023",
    userGain: 951.17,
    userGainValue: 1051166.28,
  },
  {
    id: 74,
    year: "01/02/2023",
    userGain: 957.19,
    userGainValue: 1057186.6,
  },
  {
    id: 75,
    year: "01/03/2023",
    userGain: 1021.87,
    userGainValue: 1121867.2,
  },
  {
    id: 76,
    year: "01/04/2023",
    userGain: 1035.69,
    userGainValue: 1135686.56,
  },
  {
    id: 77,
    year: "01/05/2023",
    userGain: 1036.51,
    userGainValue: 1136512.52,
  },
  {
    id: 78,
    year: "01/06/2023",
    userGain: 1053.51,
    userGainValue: 1153508.54,
  },
  {
    id: 79,
    year: "01/07/2023",
    userGain: 1032.59,
    userGainValue: 1132588.09,
  },
  {
    id: 80,
    year: "01/08/2023",
    userGain: 974.41,
    userGainValue: 1074414.25,
  },
  {
    id: 81,
    year: "01/9/2023",
    userGain: 981.54,
    userGainValue: 1081544.45,
  },
  {
    id: 82,
    year: "01/10/2023",
    userGain: 1024.27,
    userGainValue: 1124265.46,
  },
  {
    id: 83,
    year: "01/11/2023",
    userGain: 1090.75,
    userGainValue: 1190750.43,
  },
  {
    id: 84,
    year: "01/12/2023",
    userGain: 1151.97,
    userGainValue: 1251965.83,
  },
  {
    id: 85,
    year: "01/01/2024",
    userGain: 1151.23,
    userGainValue: 1251226.03,
  },
  {
    id: 86,
    year: "01/02/2024",
    userGain: 1414.44,
    userGainValue: 1514438.49,
  },
  {
    id: 87,
    year: "01/03/2024",
    userGain: 1478.66,
    userGainValue: 1578664.45,
  },
  {
    id: 88,
    year: "01/04/2024",
    userGain:1217.71,
    userGainValue: 1578664.45,
  },
  {
    id: 89,
    year: "01/05/2024",
    userGain: 1293.16,
    userGainValue: 1578664.45,
  },
  {
    id: 90,
    year: "01/06/2024",
    userGain: 1159.49,
    userGainValue: 1578664.45,
  },
];
