import React from "react";
import "./verification.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Verification = (props) => {

  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-wholes"
      show={true}
      onHide={() => props.setModalShows(false)}
    >
      <Modal.Header
        style={{ padding: "0px" , border: "none"}}
      >
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body
      style={{padding: "0px"}}
      >
        <div className="verification__main-modal">
          <img src="Images/Signin/tick-circle.svg" alt="/" />
          <p>Email address verified successfully</p>
          <button  onClick={() => navigate('/login') } >Login</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Verification;
