import { Bar } from "react-chartjs-2";
import React,{useState} from "react";

import "./RoundedBarChart.css";

export const RoundedBarChart = ({ userDataLoading,userData }) => {
 

  const [chartData, setChartData] = useState({
    labels: userData?.map((item) => item?.monthCount),
    datasets: [
      {
        label: "NFT Cost",
        data:
          !userDataLoading.loading && userData?.map((item) => item?.totalPrice),
        backgroundColor: "#00286B",
        color: '#f0f1f3',


        // borderWidth: 1,
        borderColor: "#00286B",
        borderRadius: 20,
        // barThickness: 16,
        barPercentage: 0.5,
        // maxBarThickness: 100,
        // barPercentage: 0.6,
        // categoryPercentage:0.4,
        // barThickness: 50,
      },
      {
        label: "Unrealised value",
        data:
          !userDataLoading.loading &&
          userData?.map((item) => item?.updatedPrice),
        backgroundColor: "#0F68FF",
        color: '#f0f1f3',

        // borderWidth: 1,
        borderRadius: 20,
        //  barThickness: 16,
        borderColor: "#0F68FF",
        barPercentage: 0.5,
        // barPercentage: 0.6,
        // categoryPercentage:0.4,
        // barThickness: 50,
      },
    ],
  });
  return (
    <div className="chart-container">
      <Bar
        data={chartData}
        options={{
          devicePixelRatio: 4,
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 0.5,
          plugins: {
            title: {
              display: false,
              text: "Users Gained between 2016-2020",
            },
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value, index, ticks) {
                  return "$" + value?.toLocaleString();
                },
                maxTicksLimit:10,
              },
            },
            x: {
              grid: {
                drawOnChartArea: false,
              },
            },
            // y: {
            //     grid: {
            //       drawOnChartArea: false,
            //     },

            //   },
            xAxes: [
              {
                // grid:{
                //     drawOnChartArea: false
                // }
              },
            ],
          },
        }}
      />
    </div>
  );
};
