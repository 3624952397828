import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTotalSale } from "../middleware/getNft"

const initialState = {
  loading: false,
  error: "",
  totalSale: [],
};
const saleSlice = createSlice({
  name: "getTotalSale",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalSale.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTotalSale.fulfilled, (state, action) => {
      state.loading = false;
      state.totalSale = action.payload;
    });
    builder.addCase(getTotalSale.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default saleSlice.reducer;
