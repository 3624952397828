import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import "./TableData.css";
import { useState } from "react";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";

export default function TableData(props) {
  const { nftdta, soldNft } = props;

  function createData(
    nftname,
    buydate,
    price,
    status,
    value,
    payout,
    laststatus
  ) {
    return { nftname, buydate, price, status, value, payout, laststatus };
  }
  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const rows = [
    createData(
      "M4-24 20K",
      "01/03/2024",
      "$20,000",
      "Trading",
      "$25,000",
      "$500",
      "Pending"
    ),
    createData(
      "M4-24 20K",
      "01/03/2024",
      "$20,000",
      "Trading",
      "$25,000",
      "$500",
      "Pending"
    ),
    createData(
      "M4-24 20K",
      "01/03/2024",
      "$20,000",
      "Trading",
      "$25,000",
      "$500",
      "Pending"
    ),
    createData(
      "M4-24 20K",
      "01/03/2024",
      "$20,000",
      "Trading",
      "$25,000",
      "$500",
      "Pending"
    ),

    createData(
      "M4-24 20K",
      "01/03/2024",
      "$20,000",
      "Trading",
      "$25,000",
      "$500",
      "Pending"
    ),
  ];
  const getProfite = (row) => {
    const values = row?.value / row?.quantity - row?.nftPrice;

    return values * 0.1;
  };
  const getProfiteSold = (row, index) => {
    const values = row?.transferedTo[index].value - row?.nftPrice;

    return values * 0.1;
  };
  return (
    <>
      <Modal
      
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-tabledata"
        // show={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <img
            src={"/Images/cross.svg"}
            alt="cross-img"
            className="close-btn"
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 800 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="inner-column-tb">NFT Id</TableCell>
                  <TableCell className="inner-column-tb">NFT</TableCell>
                  <TableCell className="inner-column-tb">Investment Date</TableCell>
                  <TableCell className="inner-column-tb">Inv. Price</TableCell>
                  <TableCell className="inner-column-tb">Status</TableCell>
                  <TableCell className="inner-column-tb">Value</TableCell>
                  <TableCell className="inner-column-tb">Payout</TableCell>
                  <TableCell className="inner-column-tb">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nftdta?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row.nftId}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row.nftName}
                      
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {/* {row.buydate} */}
                      {ConvertDate(row?.txDate)}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.nftPrice?.toLocaleString()}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.transfer ? "Sold" : "Trading"}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {(row?.value / row?.quantity)?.toLocaleString()}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {getProfite(row).toLocaleString()}
                      {/* {row?.value * 0.1} */}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#0F68FF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                        textAlign: "start",
                      }}
                    >
                      {row?.payout
                        ? row?.payout?.paid
                          ? "Paid"
                          : "Pending"
                        : "Pending"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal.Body>
      </Modal>
    </>
  );
}
