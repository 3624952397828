import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import MintScreen from "./Pages/MintScreen/MintScreen";
// import MintScreen50k from "./Pages/MintScreen/MintScreen50k/MintScreen50k";
import { Web3ReactProvider } from "@web3-react/core";
import { ToastContainer } from "react-toastify";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profiledetail from "./Pages/Dashboard/ProfileDetail/Profiledetail";
import Referrals from "./Pages/Dashboard/Referrals/Referrals";

import ClaimProfits from "./Components/ClaimProfits/ClaimProfits";
// import MintScreen100k from "./Pages/MintScreen/MintScreen100k/MintScreen100k";
import UserProfile from "./Pages/UserProfile/UserProfile";
import SignUpPg from "./Pages/Auth/SignUpPg/SignUpPg";
import ListedNFT from "./Pages/ListedNFT/ListedNFT";
import ListedNFTnext from "./Pages/ListedNFT/ListedNFTnext.js/ListedNFTnext";

import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./utils/Web3Connection/connectors/walletConnectV2";
import {
  hooks as metaMaskHooks,
  metaMask,
} from "./utils/Web3Connection/connectors/metaMask";

import useEagerConnect from "./hooks/Web3Connection/useEagerConnector";
import Forget from "./Pages/Auth/ForgetPage/Forget";
import LogIn from "./Pages/Auth/login/Login";
import NewPassword from "./Pages/Auth/NewPassword/NewPassword";
import ProtectedRoute from "./utils/protectedRoute/ProtectedRoute";
import Reffer from "./Pages/refffer/Reffer";
import Verification from "./Pages/Verification/Verification";
import SideDrawer from "./Components/Drawer/SideDrawer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];
function App() {
  useEagerConnect();
  const [token, setToken] = useState();
  const logoutHandle = useSelector((state) => state?.logout);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [logoutHandle]);

  const DashboardLayout = () => (
    <>
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        <Outlet /> {/* Nested routes will render here */}
      </SideDrawer>
    </>
  );
  return (
    <>
      <Web3ReactProvider connectors={connectors}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="light"
        />
        <BrowserRouter>
          <Routes>
            <Route path="/Signup" element={<SignUpPg />} />
            <Route path="/forget-password" element={<Forget />} />
            <Route path="/new-password/:id" element={<NewPassword />} />

            <Route path="/login" element={<LogIn />} />

            <Route path="/Dashboard/Referrals" element={<Referrals />} />

            <Route path="/id/:id" element={<Reffer />} />
            <Route path="/verificationsuccessful" element={<Verification />} />
            <Route path="/alreadyverified" element={<Verification />} />

            <Route path="/" element={<DashboardLayout />}>
              {/* <Route
                path="/das"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              /> */}
              <Route path="/ListedNFT" element={<ListedNFT />}></Route>
              <Route path="/ListedNFTnext" element={<ListedNFTnext />}></Route>

              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                    </ProtectedRoute>
                }
              />

              <Route
                path="/Dashboard/UserProfile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Dashboard/MintScreen"
                element={
                  <ProtectedRoute>
                    <MintScreen />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/Dashboard/Profile-Detail"
                element={
                  <ProtectedRoute>
                    <Profiledetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Dashboard/listnft"
                element={
                  <ProtectedRoute>
                    <ClaimProfits />
                    </ProtectedRoute>
                }
              />
            </Route>
            
          </Routes>
          {/* {token && (
            <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/Dashboard/UserProfile"
                  element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Dashboard/MintScreen"
                  element={
                    <ProtectedRoute>
                      <MintScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/MintScreen-50k"
                  element={
                    <ProtectedRoute>
                      <MintScreen50k />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/MintScreen-100k"
                  element={
                    <ProtectedRoute>
                      <MintScreen100k />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Dashboard/Profile-Detail"
                  element={
                    <ProtectedRoute>
                      <Profiledetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Dashboard/listnft"
                  element={
                    <ProtectedRoute>
                      <ClaimProfits />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </SideDrawer>
          )} */}
        </BrowserRouter>
      </Web3ReactProvider>
    </>
  );
}

export default App;
