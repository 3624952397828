import React from "react";
import { Line } from "react-chartjs-2";
import "./LineChartShadow.css";
export default function LineChart({ chartData,chartFilter }) {
  return (
    <div className="Line-chart-container">
      <Line
        data={chartData}
        options={{
          devicePixelRatio: 4,
          responsive: true,
          maintainAspectRatio: false,
          
       
          plugins: {
            title: {
              display: false,
              text: "Users Gained between 2016-2020",
            },
            legend: {
              display: false,
            },
          },
          scales: {
           
            y: {
                padding: {
                    right: 50
                },
              beginAtZero: true,
              ticks: {
                callback: function (value, index, ticks) {
                  return +value + "%" ;
                },
                maxTicksLimit:6,
                // drawTicks: false,
              
               
              },

            //   border: {
            //     display: false,
            //   },
            },
            x: {
                // ticks: {
                //   // display: chartFilter==="Monthly"? false:true 
                // },
              grid: {
                drawOnChartArea: false,
                
                // drawTicks: false,
              },
              display: chartFilter==="Monthly"? false:true 
             
            },
            yAxes: [
              {
                ticks: {
                  fontSize: 10,
                },
              },
            ],

            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                scaleFontSize: 10,
                ticks: {
                  fontSize: 10,
                },

                // grid: {
                //   drawOnChartArea: false,
                // },
              },
            ],
          },
          
        }}
      />
    </div>
  );
}
