import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState } from "react";
import { Data } from "./RoundedData";
import { RoundedBarChart } from "./RoundedBarChart";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { getBarChartGraph } from "../../services/redux/middleware/getNft";
import React from "react";
import { useEffect } from "react";
import ScreenLoader from "../loader/ScreenLoader";
import TableLoader from "../TableLoader/TableLoader";
Chart.register(CategoryScale);
Chart.defaults.font.size = 14;
Chart.defaults.font.family = "Montserrat";
Chart.defaults.font.color = " #667085";
export default function MainRoundedBar({nftId,year}) {
  const userData = useSelector((state) => state?.graph?.graph?.data?.funds);
  const userDataLoading = useSelector((state) => state?.graph);
  const dispatch = useDispatch();
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    const data={
      userId:userId,
      nftId:nftId,
      year:year
    }
    dispatch(getBarChartGraph(data));
  }, [nftId,year]);



  return (
    <div className="">
      {userDataLoading.loading ? (
        <TableLoader/>
      ) : (
        <RoundedBarChart
          userDataLoading={userDataLoading}
          userData={userData}
        />
      )}
    </div>
  );
}
