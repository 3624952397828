import SideDrawer from "../../Components/Drawer/SideDrawer";
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./Dashboard.css";
import { Button } from "@mui/material";
import BarChart from "../../Components/Barchart/Barchart";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Linechart from "../../Components/Linechart/Linechart";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MainRoundedBar from "../../Components/RoundedBarChart/MainRoundedBar";
import LineChartMain from "../../Components/LineChartStroke/LineChartMain";
import { useNavigate } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getBarChartGraph,
  getNftData,
  getNftTableData,
} from "../../services/redux/middleware/getNft";
import ScreenLoader from "../../Components/loader/ScreenLoader";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDropDown, setSelectedDropDown] = useState("All Time");
  const [selectedItem, setSelectedItem] = useState("All NFTs");
  const [selectedNftId, setSelectedNftId] = useState();
  const [nftYear, setNftYear] = useState(2024);
  const [nftYearMain, setNftYearMain] = useState("Year");

  const [lastRefreshTime, setLastRefreshTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [updateRefresh, setUpdateRefresh] = useState(false);
  const [chartFilter, setChartFilter] = useState("Yearly");

  const nftData = useSelector(
    (state) => state?.nftData?.nftData?.data?.userData
  );

  const handleRefresh = () => {
    setUpdateRefresh(true);
    setLastRefreshTime(Date.now());
    setElapsedTime(0); // Reset elapsed time

    const userId = localStorage.getItem("_id");
    const data = {
      userId: userId,
      nftId: selectedNftId,
      year: nftYear,
    };
    dispatch(getBarChartGraph(data));
  };
  const nftTable = useSelector((state) => state?.nftTable?.nftDataTable?.data);
  const nftTableLoader = useSelector((state) => state?.nftTable);
  const fundsData = useSelector((state) => state?.graph?.graph?.data?.funds);
  let lastPositivePrice;

  if (fundsData && fundsData.length > 0) {
    lastPositivePrice = [...fundsData]
      ?.reverse()
      ?.find((item) => item?.updatedPrice > 0);
  }

  const nftDataLoader = useSelector((state) => state?.nftData);

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };
  const calculateMonthsLeft = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const oneYearLaterDate = new Date(createdAtDate);
    oneYearLaterDate.setFullYear(createdAtDate.getFullYear() + 1);

    const currentDate = new Date();
    const timeDiff = oneYearLaterDate.getTime() - currentDate.getTime();
    const monthsLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30)); // Assuming 30 days per month

    return monthsLeft;
  };
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    setUserId(userId);
    dispatch(getNftData(userId));
    const data = {
      id: userId,
      page: 1,
    };
    dispatch(getNftTableData(data));
  }, [updateRefresh]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minutes and ${remainingSeconds} seconds`;
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (lastRefreshTime) {
  //       const currentTime = Date.now();
  //       const elapsedMilliseconds = currentTime - lastRefreshTime;
  //       const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000); // Convert milliseconds to seconds
  //       setElapsedTime(elapsedSeconds);
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [lastRefreshTime]);

  const handleChangePagitation = (event, value) => {
    const data = {
      id: userId,
      page: value ? value : 1,
    };
    dispatch(getNftTableData(data));
    setCurrentPage(value);

    // You may want to fetch new data based on the new page value
  };
  const userFilterData = nftTable?.nftData?.filter((row) => {
    const currentDate = new Date();
    const createdAtDate = new Date(row?.txDate); // Assuming createdAt is a date string

    switch (selectedDropDown) {
      case "This Week":
        const oneWeekAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= oneWeekAgo;

      case "1 Year":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return createdAtDate >= oneYearAgo;
      case "90 Days":
        const ninetyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 90)
        );
        return createdAtDate >= ninetyDaysAgo;
      case "30 Days":
        const thirtyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 30)
        );
        return createdAtDate >= thirtyDaysAgo;
      case "7 Days":
        const sevenDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= sevenDaysAgo;
      default:
        return true; // Return true to include the profile in the "All" range
    }
  });

  return (
    <>
      {nftTableLoader?.loading && <ScreenLoader />}
      {nftDataLoader.loading && <ScreenLoader />}
      {/* <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}> */}
      {/* <div>
          <p className="dash-heading">Web3Fund Beta Dashboard</p>
          <p className="dash-subhead">
            Analyse and track your performance all in one place
          </p>
        </div> */}
      <div className="dash-row-1">
        <div className="total-balance-card">
          <div onClick={handleRefresh} className="refresh-button">
            <img src="/Images/Dashboard/refresh.svg" />
          </div>
          <div>
            <p className="total-balance-txt">Your Total Balance</p>

            <div className="dollar-percent-contain">
              {nftData?.totalValue === 0 ? (
                <p className="dollar-value">$0.00</p>
              ) : (
                <p className="dollar-value">
                  {`$${nftData?.totalValue?.toLocaleString()}`}
                </p>
              )}

              <p className="percent-value">
                {nftData?.totalValue > 0 && nftData?.totalPrice > 0 && (
                  <>
                    +
                    {(
                      ((nftData?.totalValue - nftData?.totalPrice) /
                        nftData?.totalPrice) *
                      100
                    ).toFixed(2)}
                    %
                  </>
                )}
              </p>
            </div>
            {/* <p className="updated-txt">Last updated on {lastRefreshTime && formatTime(elapsedTime)} minutes ago</p> */}
          </div>

          <div className="btns-container">
            <Button
              className="Claim-btn"
              onClick={() => navigate("/Dashboard/listnft")}
            >
              List NFT
            </Button>

            <Button
              onClick={() => {
                navigate("/Dashboard/MintScreen");
              }}
              className="buy-nft-btn"
            >
              Buy NFT
            </Button>
          </div>
          <div className="info-div">
            <img src="/Images/Dashboard/info-circle.svg" alt="info-circle" />
            <p className="info-txt">Total balance from your NFTs</p>
          </div>
        </div>
        <div className="web3-nfts-card">
          <div className="myweb-btn-contain">
            <div>
              <p className="nfts-txt">My Web3Fund NFTs</p>
              <p className="select-nft-txt">
                Select an NFT to view performance and analytics
              </p>
            </div>
            <div>
              <Button
                onClick={() => {
                  navigate("/Dashboard/MintScreen");
                }}
                className="buy-web3nft-btn"
              >
                <img src="/Images/Dashboard/add.svg" alt="/" /> Buy Web3Fund NFT
              </Button>
            </div>
          </div>
          <div
            style={{ overflow: "auto", width: "100%", gap: "20px" }}
            className="d-flex"
          >
            {nftData?.nftData?.length > 0 ? (
              nftData?.nftData?.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      style={{
                        cursor: "pointer",
                        width: "255px",
                        height: "160px",
                      }}
                      onClick={() => {
                        navigate("/Dashboard/listnft");
                      }}
                      src={`/Images/MIntedMonthly/${item?.monthCount + 1}.svg`}
                      alt="card-20k"
                    />
                    <p className="nftId-main">{item?.nftId}</p>
                  </div>
                );
              })
            ) : (
              <div className="d-flex justify-content-center w-100">
                <div className="buy-nft-card">
                  <div className="d-flex align-items-center nft-tiled-card justify-content-center flex-column">
                    <img src="/Images/ListedNFT/No-Nft.svg" alt="No Nft" />
                    <p className="No-Nft-head">No NFTs Available</p>
                  </div>
                  <div>
                    <p className="no-nft-des">
                      You currently do not have any NFT associated to your
                      wallet
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* <div>
                <img src="/Images/Dashboard/Web3Fund-new.svg" alt="card-20k" />
              </div>
              <div>
                <img src="/Images/Dashboard/Web3Fund-new2.svg" alt="card-50k" />
              </div>
              <div className="card__image">
                <img src="/Images/Dashboard/Cards-new.svg" alt="card-100k" />
              </div>
              <div className="buy-nft-card">
                <Button
                  className="buy-nft-card-btn"
                  onClick={() => {
                    navigate("/Dashboard/MintScreen");
                  }}
                >
                  {" "}
                  + Buy NFT
                </Button>
              </div> */}
        </div>
      </div>

      <div className="row-2">
        <div className="bar-contain">
          <div className="dropdown-text-container">
            <div>
              <p className="nft-growth-txt">NFT Value Growth</p>
            </div>
            <div className="both-dropdown-contain">
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-yearly"
                  >
                    {selectedItem}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("All NFTs");
                        setSelectedNftId();
                      }}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M1-24");
                        setSelectedNftId("M1-24");
                      }}
                    >
                      M1-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M2-24");
                        setSelectedNftId("M2-24");
                      }}
                    >
                      M2-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M3-24");
                        setSelectedNftId("M3-24");
                      }}
                    >
                      M3-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M4-24");
                        setSelectedNftId("M4-24");
                      }}
                    >
                      M4-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M5-24");
                        setSelectedNftId("M5-24");
                      }}
                    >
                      M5-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M6-24");
                        setSelectedNftId("M6-24");
                      }}
                    >
                      M6-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M7-24");
                        setSelectedNftId("M7-24");
                      }}
                    >
                      M7-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M8-24");
                        setSelectedNftId("M8-24");
                      }}
                    >
                      M8-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M9-24");
                        setSelectedNftId("M9-24");
                      }}
                    >
                      M9-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M10-24");
                        setSelectedNftId("M10-24");
                      }}
                    >
                      M10-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M11-24");
                        setSelectedNftId("M11-24");
                      }}
                    >
                      M11-24
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedItem("M12-24");
                        setSelectedNftId("M12-24");
                      }}
                    >
                      M12-24
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-yearly"
                  >
                    {nftYearMain}
                    {/* {nftYear} */}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setNftYearMain("Year");
                      }}
                    >
                      Year
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        setNftYear(2023);
                        setNftYearMain(2023);
                      }}
                    >
                      2023
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setNftYear(2024);
                        setNftYearMain(2024);
                      }}
                    >
                      2024
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setNftYear(2025);
                        setNftYearMain(2025);
                      }}
                    >
                      2025
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="bar-content">
            {/* <BarChart /> */}
            <MainRoundedBar nftId={selectedNftId} year={nftYear} />
            <div className="barchart-btm">
              <div className="invested-container">
                <img src="/Images/Dashboard/invested.svg" alt="invested" />
                <div>
                  <p className="invested-txt">Total NFT Cost</p>
                  <p className="invested-value">
                    $
                    {/* {nftData?.totalPrice > 0
                        ? nftData?.totalPrice?.toLocaleString()
                        : 0} */}
                    {lastPositivePrice?.totalPrice
                      ? lastPositivePrice?.totalPrice?.toLocaleString()
                      : "0"}
                  </p>
                </div>
              </div>
              <div className="invested-container">
                <img
                  src="/Images/Dashboard/liveholdings.svg"
                  alt="Live-holdings"
                />
                <div className="w-100">
                  <p className="invested-txt">Unrealized NFT Value</p>
                  <p className="invested-value">
                    $
                    {/* {nftData?.totalValue > 0
                        ? (nftData?.totalValue?.toLocaleString())
                        : 0} */}
                    {lastPositivePrice?.updatedPrice
                      ? lastPositivePrice?.updatedPrice?.toLocaleString()
                      : "0"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="linebar-contain">
          <div className="dropdown-text-container">
            <div>
              <p className="nft-growth-txt">Global Web3Fund Growth</p>
              {/* <p className="view-txt">Total Fund Growth</p> */}
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-yearly"
                >
                  {chartFilter}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setChartFilter("Yearly");
                    }}
                  >
                    Yearly
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setChartFilter("Monthly");
                    }}
                  >
                    Monthly
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setChartFilter("Quaterly");
                    }}
                  >
                    Quaterly
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="bar-content">
            <LineChartMain chartFilter={chartFilter} />
            {/* <Linechart /> */}
            {/* <div className="barchart-btm">
              <div className="invested-container">
                <img src="/Images/Dashboard/deposit.svg" alt="Deposit" />
                <div>
                  <p className="invested-txt">2023 Growth</p>
                  <p className="invested-value">430%</p>
                </div>
              </div>
              <div className="invested-container">
                <img src="/Images/Dashboard/liveholdings.svg" alt="Total Aum" />
                <div className="w-100">
                  <p className="invested-txt">2024 Projection</p>
                  <p className="invested-value">350%</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="row-3" style={{ overflowX: "auto" }}>
        <div className="table-above-row">
          <p className="table-main-head">Web3Fund NFT History</p>
          <div className="table-top-lhs">
            <div>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="dropdown-week">
                  <img src="/Images/Dashboard/calendar-2.svg" alt="/" />{" "}
                  {selectedDropDown}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("All Time");
                    }}
                  >
                    All Time
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("1 Year");
                    }}
                  >
                    1 Year
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("90 Days");
                    }}
                  >
                    90 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("30 Days");
                    }}
                  >
                    30 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("7 Days");
                    }}
                  >
                    7 Days
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {nftTable?.nftData?.length > 0 ? (
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ overflowX: "auto" }} aria-label="simple table">
              <TableHead sx={{ minHeight: "52px" }}>
                <TableRow className="columns-name" sx={{ minHeight: "52px" }}>
                  <TableCell className="column-head" style={{ width: "60px" }}>
                    #
                  </TableCell>
                  <TableCell className="column-head" style={{ width: "185px" }}>
                    NFT
                  </TableCell>
                  <TableCell className="column-head" style={{ width: "90px" }}>
                    NFT ID
                  </TableCell>

                  <TableCell className="column-head" style={{ width: "170px" }}>
                    Investment Date
                  </TableCell>
                  <TableCell className="column-head" style={{ width: "130px" }}>
                    Investment Amount{" "}
                  </TableCell>
                  <TableCell className="column-head" style={{ width: "140px" }}>
                    Unrealized Value
                  </TableCell>
                  <TableCell className="column-head" style={{ width: "115px" }}>
                    Status
                  </TableCell>

                  <TableCell className="column-head" style={{ width: "165px" }}>
                    Transaction #
                  </TableCell>

                  {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {userFilterData.length > 0
                  ? userFilterData?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {row?.number}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "start",
                            textDecoration: "underline",
                          }}
                        >
                          {row?.nftName}
                        </TableCell>

                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "start",
                            textDecoration: "underline",
                          }}
                        >
                          {row?.nftId}
                        </TableCell>

                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {ConvertDate(row?.txDate)}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {`$${row?.nftPrice?.toLocaleString()}`}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {`$${(row?.value / row?.quantity)?.toLocaleString()}`}
                        </TableCell>
                        <TableCell
                          style={{
                            color: `var(--Secondary-Green-500, ${
                              row?.transfer ? "#F24444" : "#4FBE88"
                            })`,
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                          }}
                        >
                          <div
                            className={
                              row?.transfer
                                ? "status-txt-warning"
                                : "status-txt"
                            }
                          >
                            {row?.transfer ? "Inactive" : "Active"}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              `https://etherscan.io/tx/${row?.tx}`,
                              "_blank"
                            );
                          }}
                        >
                          {`${row?.tx?.slice(0, 8)}....${row?.tx?.slice(-3)}`}
                          {/* {row?.tx} */}
                        </TableCell>
                      </TableRow>
                    ))
                  : "No Data Avalible"}
              </TableBody>
            </Table>
            <hr className="hr-above"></hr>
            <Stack spacing={8} className="stack-contain">
              <Pagination
                count={nftTable?.totalPages}
                page={currentPage}
                variant="outlined"
                shape="rounded"
                siblingCount={0}
                boundaryCount={2}
                onChange={handleChangePagitation}
              />
            </Stack>
          </TableContainer>
        ) : (
          <div className="nodata-contain">
            <img src="/Images/not found.svg" alt="NO DATA" />
            <p className="nodata-txt">No NFTs Available</p>
            <p className="nodata-des">
              You currently do not have any NFT associated to your wallet
            </p>
          </div>
        )}
      </div>
      {/* </SideDrawer> */}
    </>
  );
}
