import PropTypes from "prop-types";
import { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import React, { useEffect } from "react";
import "./MyReferralTable.css";
import { useSelector, useDispatch } from "react-redux";
import { getReferalData } from "../../services/redux/middleware/getNft";
import TableData from "../modal/TableData/TableData";
import TableLoader from "../TableLoader/TableLoader";
import ScreenLoader from "../loader/ScreenLoader";

export default function MyReferralTable({ selectedDropDown }) {
  const [showTb, setShowTb] = useState(false);
  const [nftDatas, setNftsData] = useState();
  const [soldNfts, setSoldNfts] = useState();

  const [currentPage, setCurrentPage] = useState(1);

  const handleModalOpen = (data, soldNft) => {
    setNftsData(data);
    // setSoldNfts(soldNft);

    setShowTb(!showTb);
  };
  const thisisrefer = useSelector((state) => state?.referal?.referalDetail);
  const refferLoader = useSelector((state) => state?.referal);

  const dispatch = useDispatch();

  const handleChangePagitation = (event, value) => {
    // Handle page change here
    const userId = localStorage.getItem("_id");
    const data = {
      id: userId,
      page: value ? value : 1,
    };
    dispatch(getReferalData(data));

    setCurrentPage(value);
  };

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };
  // useEffect(() => {
  //   const userId = localStorage.getItem("_id");
  //   const data = {
  //     id: userId,
  //   };

  //   dispatch(getReferalData(data));
  // }, []);

  const getNftVlaue = (data) => {
    let totalValue = 0;
    data.forEach((nft) => {
      totalValue = totalValue + nft.value;
    });


    return totalValue;
  };

  function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            component="th"
            scope="row"
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "160%",
              textAlign: "start",
            }}
          >
            {row?.userNumber}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "start",
            }}
          >
            {row?.name}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "start",
            }}
          >
            {row?.email}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "start",
            }}
          >
            {row?.referralId}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "start",
            }}
          >
            {ConvertDate(row?.createdAt)}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "start",
            }}
          >
            {row?.nfts?.length}
          </TableCell>
          <TableCell
            style={{
              color: " #1D1F2C",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              textAlign: "start",
            }}
          >
            ${row?.totalSale.toLocaleString()}
          </TableCell>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              disabled={row?.nfts?.length === 0 && true}
              // onClick={() => setOpen(!open)}
              onClick={() => {
                handleModalOpen(row?.nfts);
              }}
            >
              {/* {open ? ( */}
              {showTb ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <VisibilityOutlinedIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: "none",
              padding: 0,
            }}
            colSpan={12}
          ></TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const userFilterData = thisisrefer?.data?.data?.filter((row) => {
    const currentDate = new Date();
    const createdAtDate = new Date(row?.createdAt); // Assuming createdAt is a date string

    switch (selectedDropDown) {
      case "This Week":
        const oneWeekAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= oneWeekAgo;

      case "1 Year":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return createdAtDate >= oneYearAgo;
      case "90 Days":
        const ninetyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 90)
        );
        return createdAtDate >= ninetyDaysAgo;
      case "30 Days":
        const thirtyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 30)
        );
        return createdAtDate >= thirtyDaysAgo;
      case "7 Days":
        const sevenDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= sevenDaysAgo;
      default:
        return true; // Return true to include the profile in the "All" range
    }
  });

  return (
    <>
      {/* <button onClick={() => setShowTb(true)}>Open Table Modal</button> */}
      <TableData
        nftdta={nftDatas}
        // soldNft={soldNfts}
        show={showTb}
        onHide={() => {
          setShowTb(false);
        }}
      />
      {thisisrefer &&
      thisisrefer !== undefined &&
      thisisrefer?.data?.data?.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow className="columns-user">
                <TableCell className="column-head">#</TableCell>
                <TableCell className="column-head">Name</TableCell>
                <TableCell className="column-head">Email</TableCell>
                <TableCell className="column-head">ID</TableCell>
                <TableCell className="column-head">Date</TableCell>
                <TableCell className="column-head">No.of NFTs</TableCell>
                <TableCell className="column-head">NFTs Value</TableCell>
                <TableCell className="column-head">View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refferLoader.loading ? (
                <ScreenLoader />
              ) : userFilterData && userFilterData !== undefined ? (
                userFilterData?.map((row, index) => (
                  <Row key={index} row={row} index={index} />
                ))
              ) : (
                ""
              )}
            </TableBody>
          </Table>

          <Stack spacing={8} className="stack-contain pt-4">
            <Pagination
              count={thisisrefer?.data?.totalPages}
              page={currentPage}
              onChange={handleChangePagitation}
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              boundaryCount={2}
            />
          </Stack>
        </TableContainer>
      ) : (
        <div className="no-referral-contain">
          <img src="/Images/no referral shown.svg" alt="No Referral Found" />
          <p className="no-referral-txt">No referrals available</p>
          <p className="no-referral-des">
            You currently haven't referred any new users to Web3Fund{" "}
          </p>
        </div>
      )}
    </>
  );
}
