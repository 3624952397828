import { configureStore } from "@reduxjs/toolkit";
import signup from "./reducer/signup";
import nftData from "./reducer/getNft";
import nftTable from "./reducer/getNftTable";
import getUserInfo from "./reducer/getUserInfo";
import referal from "./reducer/getReferal";
import history from "./reducer/getUserHistory";
import getProfileImage from "./reducer/getProfileImage";
import graph from "./reducer/getBarChart"
import getListNft from "./reducer/getListNft";
import getTotalSale from "./reducer/getTotalSale";
import getLogout from "./reducer/getLogout";

export const store = configureStore({
  reducer: {
    profile: signup,
    nftData: nftData,
    nftTable: nftTable,
    profileInfo: getUserInfo,
    referal: referal,
    history: history,
    profileImage: getProfileImage,
    graph: graph,
    listNft: getListNft,
    totalSale:getTotalSale,
    logout:getLogout


  },
});
