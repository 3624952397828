import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

export default function Reffer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Get the current pathname
  useEffect(() => {
    Cookies.set("reffer", id);
    navigate("/login");
  }, [id, navigate]);

  return <></>;
}
