import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { useState } from "react";
import "./AccountHistoryTable.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { getUserHistory } from "../../services/redux/middleware/getNft";
import TableLoader from "../TableLoader/TableLoader";

export default function AccountHistoryTable({ selectedDropDown }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const historyData = useSelector((state) => state?.history?.userHistory?.data);
  // useEffect(() => {
  //   const userId = localStorage.getItem("_id");
  //   const data = {
  //     id: userId,
  //     page: 1,
  //   };

  //   dispatch(getUserHistory(data));
  // }, []);

  const ConvertDate = (originalDateTimeStr) => {
    const originalDateTime = new Date(originalDateTimeStr);
    const formattedDateTime = originalDateTime.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
    });
    return formattedDateTime;
  };

  const userFilterData = historyData?.userHistory?.filter((row) => {
    const currentDate = new Date();
    const createdAtDate = new Date(row?.txDate); // Assuming createdAt is a date string

    switch (selectedDropDown) {
      case "This Week":
        const oneWeekAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= oneWeekAgo;

      case "1 Year":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return createdAtDate >= oneYearAgo;
      case "90 Days":
        const ninetyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 90)
        );
        return createdAtDate >= ninetyDaysAgo;
      case "30 Days":
        const thirtyDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 30)
        );
        return createdAtDate >= thirtyDaysAgo;
      case "7 Days":
        const sevenDaysAgo = new Date(
          currentDate.setDate(currentDate.getDate() - 7)
        );
        return createdAtDate >= sevenDaysAgo;
      default:
        return true; // Return true to include the profile in the "All" range
    }
  });

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    const userId = localStorage.getItem("_id");
    const data = {
      id: userId,
      page: page,
    };

    dispatch(getUserHistory(data));
  };
  return (
    <>
      {historyData?.userHistory && historyData?.userHistory?.length ? (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table
            sx={{
              minWidth: 400,
              "& .MuiTableCell-sizeMedium": {
                padding: "16px 24px",
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className="columns-name">
                <TableCell className="column-head" width={"45px"}>
                  #
                </TableCell>
                <TableCell className="column-head" width={"210px"}>
                  Action
                </TableCell>
                <TableCell className="column-head" width={"220px"}>
                  Date
                </TableCell>
                <TableCell className="column-head" width={"653px"}>
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userFilterData?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "160%",
                      textAlign: "start",
                    }}
                  >
                    {row?.number}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                      textAlign: "start",
                    }}
                  >
                    {row.action}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                      textAlign: "start",
                    }}
                  >
                    {ConvertDate(row?.txDate)}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "var(--Black-500---Primary, #1D1F2C)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                      textAlign: "start",
                    }}
                  >
                    {row.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <hr className="hr-above"></hr>
          <Stack spacing={8} className="stack-contain">
            {/* <Pagination
              count={historyData?.totalPages}
              page={historyData?.currentPage}
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              boundaryCount={2}
            /> */}
            <Pagination
              count={historyData?.totalPages}
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              boundaryCount={2}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        </TableContainer>
      ) : (
        <div className="no-referral-contain">
          <img src="/Images/no history.svg" alt="No History Found" />
          <p className="no-referral-txt">No history available</p>
          <p className="no-referral-des">
            You currently do not have any history associated to your account{" "}
          </p>
        </div>
      )}
    </>
  );
}
