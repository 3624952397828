import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import "./notverified.css"
import { useDispatch } from 'react-redux';
import { resendEamil } from '../../../services/redux/middleware/signin';
import { ErrorToast, SuccessToast } from '../../toast/Toast';

const NotVerified = (props) => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)

  const ResendEmail = async () => {
    try {
      setLoader(true)
      const data = localStorage.getItem("_id")
      dispatch(resendEamil(data)).then((res) => {
        if (res?.payload?.status === 200) {
          SuccessToast("Email Sent successfully")
          props.setModalShows(false)

        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      })



    } catch {

    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-whole not-verified-Modal"
      show={props.show}
      onHide={() => props.setModalShows(false)}
    >

      <Modal.Body
        style={{
          background: "white",
          borderRadius: "100px",
          borderTop: "none",
          color: "black",
          width: "100%",
          height: "100%",
          justifyContent: "center",
        }}
        className="main-section-wallet"
      >
        <div className='forgetpass'>
          <div className='forgetpass__main not-verified-pad not-v'>
            <img src='/Images/Signin/danger.svg' alt='/' />
            <h1>Email address not verified</h1>
            <button onClick={() => { ResendEmail() }}>Resend Email</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NotVerified