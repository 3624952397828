import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const signup = createAsyncThunk("signin", async (data) => {
  try {
    const res = await api.post(`${API_URL}/auth/signup`, data);
    // localStorage.setItem("token", res?.data?.token);
    return {
      status: res?.status,
      data: res?.data?.data,
      token: res?.data?.token,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});
export const resendEamil = createAsyncThunk("resendEamil", async (data) => {
  try {
    const res = await api.post(`${API_URL}/api/resendEmail/${data}`);
    // localStorage.setItem("token", res?.data?.token);
    return {
      status: res?.status,
      data: res?.data?.data,
      token: res?.data?.token,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const signin = createAsyncThunk("signin", async (data) => {
  try {
    const res = await api.post(`${API_URL}/auth/login`, data);
    // localStorage.setItem("token", res?.data?.token);
    return {
      status: res?.status,
      data: res?.data?.data,
      token: res?.data?.token,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const forgetPassword = createAsyncThunk(
  "forgetpassword",
  async (data) => {
    try {
      const res = await api.post(`${API_URL}/auth/forgot`, data);
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data?.data,
        token: res?.data?.token,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
export const newPassword = createAsyncThunk("newPassword", async (data) => {
  try {
    const res = await api.post(`${API_URL}/auth/reset/${data.token}`, {
      newPassword: data.password,
    });
    // localStorage.setItem("token", res?.data?.token);
    return {
      status: res?.status,
      data: res?.data?.data,
      token: res?.data?.token,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const getProfile = createAsyncThunk("newPassword", async (data) => {
  try {
    const res = await api.get(`${API_URL}/api/userProfile/${data}`);
    // localStorage.setItem("token", res?.data?.token);
    return {
      status: res?.status,
      data: res?.data?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

export const addWalletAddress = createAsyncThunk(
  "addWalletAddress",
  async (data) => {
    try {
      const res = await api.post(`${API_URL}/api/addWallet/${data.id}`, {
        walletAddress: data.walletAddress,
      });
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
export const addEmailAddressGoogle = createAsyncThunk(
  "addEmailAddressGoogle",
  async (data) => {
    try {
      const res = await api.post(
        `${API_URL}/api/addEmailAddressGoogle/${data.id}`,
        {
          email: data.email,
        }
      );
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
export const addEmailAddress = createAsyncThunk(
  "addEmailAddressGoogle",
  async (data) => {
    try {
      const res = await api.post(`${API_URL}/api/addEmailAddress/${data.id}`, {
        email: data.email,
        password: data?.password,
      });
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getProfileInfo = createAsyncThunk(
  "getProfileInfo",
  async (data) => {
    try {
      const res = await api.get(`${API_URL}/api/getUserInfo/${data}`);
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);

export const getProfileImage = createAsyncThunk(
  "getProfileImage",
  async (data) => {
    try {
      const res = await api.get(`${API_URL}/api/getUserProfileImage/${data}`);
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
export const checkWalletExists = createAsyncThunk(
  "checkWalletExists",
  async (data) => {
    try {
      const res = await api.get(`${API_URL}/api/checkWallet/${data?.userId}/${data?.wallet}`);
      return {
        status: res?.status,
        data: res?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);