import React, { useState } from "react";
import SignUp from "../../../Components/modal/SignUp/SignUp";
import "./login.css";
import SignIn from "../../../Components/modal/SignIn/SignIn";
export default function LogIn() {
  const [shows, setModalsShow] = useState(true);

  return (
    <>
      <div className="SignUp-PG">
        {/* <SignUp show={shows} setModalShows={setModalsShow} /> */}
        <SignIn show={shows} setModalShows={setModalsShow}/>
      </div>
    </>
  );
}
