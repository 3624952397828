import React, { useEffect } from "react";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import "./MintScreen.css";
import { useNavigate } from "react-router-dom";
import useMintRead from "../../hooks/Contract/useMintRead";
import useWeb3 from "../../hooks/Web3Connection/useWeb3";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import useMint from "../../hooks/Contract/useMint";
import { useWeb3React } from "@web3-react/core";
import { ErrorToast } from "../../Components/toast/Toast";
import Web3Modal from "../../Components/modal/Web3modal/Web3Modal";
import { SuccessToast } from "../../Components/toast/Toast";
import { createNft } from "../../services/redux/middleware/creteNFt";
import useWhiteList from "../../hooks/Contract/useWhiteList";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import useEagerConnect from "../../hooks/Web3Connection/useEagerConnector";
import AddWallet from "../../Components/modal/Add Wallet/AddWallet";
import { getProfile } from "../../services/redux/middleware/signin";
import { getTotalSale } from "../../services/redux/middleware/getNft";
import useCheckCurrentPhase from "../../hooks/Contract/useCheckCurentPhase";

export default function MintScreen({ open }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  useEagerConnect();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [userWhiteList, setUserWhiteList] = useState();
  const [dataLoader, setDataLoader] = useState();
  const { account, chainId } = useWeb3React();
  const [quantity, setQuantity] = useState(1);
  const { mintReadHook } = useMintRead();
  // const { mintQuantityReadHook } = useQuantityRead();
  const { checkcurrentPhaseHook } = useCheckCurrentPhase();
  const { mintHook, statusState } = useMint({ account });
  const [price, setPrice] = useState(0);
  const [loader, setLoader] = useState();
  const [openmodal, setOpenModal] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState();
  const [mintLoader, setMintLoader] = useState(false);
  const [checkWhiteList, setCheckWhiteList] = useState();
  const { whiteListReadHook } = useWhiteList();
  const [monthIndex, setMonthIndex] = useState();
  const userData = useSelector((state) => state?.profile?.profile?.data);
  const totalSale = useSelector((state) => state?.totalSale?.totalSale?.data);

  const dispatch = useDispatch();

  const customweb3 = useWeb3();

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const hadleGetValue = async () => {
    const hash = await mintReadHook(customweb3);
    console.log("this is the nft price",hash)
    setLoader(false);

    setPrice(hash);
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const convertWeiToEther = (weiValue) => {

    const wei = parseFloat(weiValue);
    const ether = wei / Math.pow(10, 6);
    return ether;
  };
  const handleMint = async () => {
    if (!account) {
      setOpenModal(true);
      return;
    }
    try {
      setMintLoader(true);
      const convertedPrice = convertWeiToEther(price);
      // createnft();
      const hash = await mintHook(
        account,
        customweb3,
        0,
        quantity,
        quantity * convertedPrice
      );
      setDataLoader(true);
      await UploadNft(
        hash?.events?.NFTMinted?.transactionHash,
        hash?.events?.NFTMinted?.returnValues?.quantity,
        hash?.events?.NFTMinted?.returnValues?.tokenId
      );
      setMintLoader(false);
    } catch (e) {
      let extractedMessage;
      extractedMessage = e.toString().split(":");
      setMintLoader(false);
      ErrorToast(extractedMessage[1] + extractedMessage[2].split("\n")[0]);
    }
  };

  async function UploadNft(hash, quantitys, tokenId) {
    try {
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      const userid = await localStorage.getItem("_id");
      var currentDate = new Date();

      var currentMonthIndex = currentDate.getMonth();
      var currentMonth = monthNames[currentMonthIndex];

      const data = {
        userId: userid,
        nftPrice: 20000,
        nftName: `M${currentMonthIndex + 1}-24`,
        nftId: tokenId,
        month: currentMonth,
        walletAddress: account,
        year: currentYear,
        quantity: quantitys,
        monthCount: currentMonthIndex,
        tx: hash,
      };
      dispatch(createNft(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          SuccessToast("NFT Bought Successfully");
          dispatch(getTotalSale());
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log("Error", error);
    }
  }

  const handleWhiteList = async () => {
    const hash = await whiteListReadHook(customweb3, 0, account);
    console.log("userWhite list", hash, account);
    setUserWhiteList(hash);
  };
  const handleCheckWhiteListStatus = async () => {
    const hash = await checkcurrentPhaseHook(customweb3, account);
    setCheckWhiteList(hash);
  };

  useEffect(() => {
    var currentDate = new Date();
    var currentMonthIndex = currentDate.getMonth();
    setMonthIndex(currentMonthIndex);
  }, []);

  useEffect(() => {
    setLoader(true);
    hadleGetValue();
    handleCheckWhiteListStatus();
    // hadleGetQuantityValue();
  }, [dataLoader]);

  useEffect(() => {
    dispatch(getTotalSale());
  }, []);
  useEffect(() => {
    if (account) {
      handleWhiteList();
    }
  }, [account, dataLoader]);

  useEffect(() => {
    const id = localStorage.getItem("_id");
    dispatch(getProfile(id));
  }, []);

  useEffect(() => {
    const profile = localStorage.getItem("profileupdate");
    if (profile === "false") {
      setProfileUpdated(false);
    } else {
      setProfileUpdated(true);
    }
  }, [account]);

  const [className, setClassName] = useState("");
  const [ShowAddWallet, setShowAddWallet] = useState(false);

  const quantityOfEth = convertWeiToEther(price) * quantity;
  return (
    <>
      {loader && <ScreenLoader />}
      {mintLoader && <ScreenLoader text={statusState} />}

      <Web3Modal show={openmodal} setModalShows={setOpenModal} />

      <div className="Mint-whole">
        <div className="Section-1-20">
        <div className={isMobile && "w-100" }>
          <img
            src={`/Images/MIntedMonthly/${monthIndex + 1}.svg`}
            alt="Web3Fund"
            className="web3-img"
          />
        </div>
          <div className="padd-main">
            <h1 className="Heading-20k">M{monthIndex + 1}-24 20k</h1>

            <p className="sub-txt">
              The M{monthIndex + 1}-24 20k NFT serves as your gateway to the
              exclusive
              <br></br> Web3Fund, offering a $20,000 stake from {monthNames[monthIndex]} 2024.{" "}
            </p>
            <p className="sub-txt sub-update-new">
              {" "}
              By acquiring this asset, you gain privileged access to the
              <br></br> digital revolution and a share of all future profits
              generated.
            </p>
            {/* <div className="print-check_main">
              <div className="printing-check">
                <label class="form-check-label " for="flexCheckDefault1">
                  <p className={!isChecked ? "label-txt" : "label-txt"}>
                    M3-24 20k{" "}
                  </p>
                  <p className="Total-sales">
                    Total Sales - ${totalSale > 0 ? totalSale : 0}
                  </p>
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input "
                    type="checkbox"
                    value=""
                    checked
                    onChange={() => {
                      setIsChecked(true);
                      setIsChecked2(false);
                      setIsChecked3(false);
                    }}
                    id="flexCheckDefault1"
                  />
                </div>
              </div>
            </div> */}
          </div>
          
          {/* <div  className={`${open ? "tester" : "tester"}`}> */}
        </div>
        <div className="Row-2-mint">
          <div className="Row-2-inner-mint">
            <div className="Row2-LHS">
              {account ? (
                checkWhiteList ? (
                  userWhiteList ? (
                    <div className="List-price-contain">
                      <p className="Lhs-label">Whitelisted</p>
                      <div className="listed-status"></div>
                    </div>
                  ) : (
                    <div className="List-price-contain">
                      <p className="Lhs-label"> Not Whitelisted</p>
                      <div className="unlisted-status"></div>
                    </div>
                  )
                ) : (
                 <></>
                )
              ) : (
                <div className="List-price-contain">
                  <p className="Lhs-label">Account Connected</p>
                  <div className="unlisted-status"></div>
                </div>
              )}
              <div className="Last-price-contain-mint">
                <p className="Lhs-label">Current price</p>
                <div className="img-txt">
                  <img
                    src="/Images/Mint-Screen/ethereum.svg"
                    alt="Last Price"
                  />
                  <p className="Lhs-value">
                    {price && convertWeiToEther(price)?.toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="quantity-contain">
                <div className="q-value-contain-mint">
                  <p className="Lhs-label">Quantity</p>
                  <p className="Lhs-value">{quantity}</p>
                </div>

                <div className="arrows-contain">
                  <img
                    src="/Images/Mint-Screen/chevron-up.svg"
                    alt="arrow-up"
                    onClick={handleIncrease}
                  />
                  <img
                    src="/Images/Mint-Screen/chevron-down.svg"
                    alt="arrow-down"
                    onClick={handleDecrease}
                  />
                </div>
              </div>
              <div>
                {userData?.walletAddress ? (
                  <Button
                    disabled={
                      checkWhiteList ? (userWhiteList ? false : true) : false
                    }
                    onClick={() => {
                      if (profileUpdated) {
                        handleMint();
                      } else {
                        ErrorToast(
                          "Your Profile is not updated. Please update your Profile to continue"
                        );
                        navigate("/Dashboard/Profile-Detail");
                      }
                    }}
                    className="Mint-btn"
                  >
                    Mint now | $ {quantityOfEth?.toLocaleString()}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      ErrorToast(
                        "Your Wallet is not stored.Please Connect your wallet first"
                      );
                      setShowAddWallet(true);
                    }}
                    className="Mint-btn"
                  >
                    Connect wallet
                  </Button>
                )}

                {/* <Button
                    disabled={userWhiteList ? false : true}
                    onClick={() => {
                      if (profileUpdated) {
                        handleMint();
                      } else {
                        ErrorToast(
                          "Your Profile is not updated. Please update your Profile to continue"
                        );
                        navigate("/Dashboard/Profile-Detail");
                      }
                    }}
                    className="Mint-btn"
                  >
                    Mint now | $ {quantityOfEth?.toLocaleString()}
                  </Button> */}
              </div>
            </div>
            {/* <div className="Row2-RHS">
              <Button className="Mint-btn">Mint now | $ 100</Button>
            </div> */}
          </div>
        </div>
        {/* <div className="Row-3">
          <div>
            <p className="Stay-txt">
              Stay upto date with<br></br> all the latest news
            </p>
            <p className="Stay-des">
              Keep informed on the cutting edge: Your go-to source for<br></br>{" "}
              the latest updates and insights.
            </p>
            <Button className="btn-join-telegram">Join our Telegram</Button>
          </div>
          <div>
            <img
              src="/Images/Mint-Screen/Latest-news.svg"
              alt="Latest-img"
              className="Latest-img"
            />
          </div>
        </div>
        <Row className="footer-mint">
          <Footer />
        </Row> */}
      </div>

      <AddWallet
        show={ShowAddWallet}
        setModalShows={setShowAddWallet}
        onHide={() => {
          setShowAddWallet(false);
        }}
      />
      {/* </SideDrawer> */}
    </>
  );
}
