import contractAbi from "../../utils/Contracts/contractAbi.json";
import { MintContract } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";
export const useMintRead = () => {
  const mintReadHook = useCallback(async (customweb3, Id) => {
    const contract = new customweb3.eth.Contract(contractAbi, MintContract);
    try {
      const estimatedGas = await contract.methods.nftPrice().estimateGas();

      const details = await contract.methods.nftPrice().call({
        gas: estimatedGas,
      });

      return details;
    } catch (error) {
      console.log(error, "errpr");
      throw error;
    }
  });
  return { mintReadHook: mintReadHook };
};
export default useMintRead;
