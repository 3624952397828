import SideDrawer from "../../../Components/Drawer/SideDrawer";
import React from "react";
import Form from "react-bootstrap/Form";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import "./Profiledetail.css";
import axios from "axios";
import { API_URL } from "../../../services/client";
import { SuccessToast } from "../../../Components/toast/Toast";
import { ErrorToast } from "../../../Components/toast/Toast";
import { useRef } from "react";
import { FormControl } from "react-bootstrap";
import SignIn from "../../../Components/modal/SignIn/SignIn";
import AddWallet from "../../../Components/modal/Add Wallet/AddWallet";
import { useWeb3React } from "@web3-react/core";
import api from "../../../services/apiInterceptor";
import SignInEmail from "../../../Components/modal/SignInEmail/SignInEmail";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getProfileImage,
} from "../../../services/redux/middleware/signin";
import { Toast } from "react-bootstrap";
import ScreenLoader from "../../../Components/loader/ScreenLoader";
import Cookies from "js-cookie";
import NotVerified from "../../../Components/modal/NotVerified/NotVerified";

export default function Profiledetail() {
  const defaultprofile = "/Images/Dashboard/profile-detail.svg";
  const userData = useSelector((state) => state?.profile?.profile?.data);
  const userLoading = useSelector((state) => state?.profile);

  const [imageSrc, setImageSrc] = useState(defaultprofile);
  const [name, setName] = useState(userData?.name);
  const [referralID, setreferralID] = useState("");
  const [walletAdd, setwalletAdd] = useState("");
  const [telegram, setTelegram] = useState("");
  const [xhandle, setXhandle] = useState("");
  const [userReffer, setUserReffer] = useState();

  const { account, chainId } = useWeb3React();
  const [email, setEmail] = useState("");
  const [ConnectOption, setConnectOption] = useState(false);
  const [ShowSignIn, setSignInShow] = useState(false);
  const [notVerified, setNotVerified] = useState(false);

  const [ShowAddWallet, setShowAddWallet] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const walletRef = useRef(null);

  const handleReferralCopy = (link) => {
    navigator.clipboard
      .writeText(`https://app.web3fund.ai/id/${link}`)
      .then(() => {
        SuccessToast("Copied");
      })
      .catch((err) => {});
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setLoader(true);

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const res = await api.post(`${API_URL}/api/upload`, formData);
        if (res.status === 200) {
          setLoader(false);
          setImageSrc(res?.data?.imageUrl);
          SuccessToast("Profile Updated Successfully");
        }
      } catch (error) {
        setLoader(false);
        console.error("Error:", error);
      }
    }
  };
  const formValidation = () => {
    if (imageSrc === "/Images/Dashboard/profile-detail.svg") {
      ErrorToast("Please upload image ");
      return false;
    } else if (!name) {
      ErrorToast("Please enter your Name");
      return false;
    } else if (!telegram) {
      ErrorToast("Please enter your Telegram Handle");
      return false;
    } else if (!xhandle) {
      ErrorToast("Please enter your X Handle");
      return false;
    }
  };

  async function addUser() {
    try {
      const formValid = formValidation();
      if (formValid === false) {
        return;
      }
      setLoader(true);
      const res = await api.post(`${API_URL}/api/addUser/${userId}`, {
        name: name,
        telegramHandle: telegram,
        xHandle: xhandle,
        referralBy: userData?.referralBy ? userData?.referralBy : userReffer,
        profilePic: imageSrc,
      });

      if (res.status === 200) {
        localStorage.setItem("profileupdate", true);
        dispatch(getProfile(userId));
        dispatch(getProfileImage(userId));
        SuccessToast("Profile Updated Successfully");
        setLoader(false);
        setEmail("");
        setName("");
        setXhandle("");
        setreferralID("");
      }
    } catch (error) {
      setLoader(false);

      console.log("Error :", error);
    }
  }

  const copyToClipboard = (userWalletAddress) => {
    navigator.clipboard
      .writeText(userWalletAddress)
      .then(() => {
        // setShowToast(true);
        SuccessToast("Copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        alert("Failed to copy referral ID to clipboard");
      });
  };

  useEffect(() => {
    const id = localStorage.getItem("_id");
    setUserId(id);
    dispatch(getProfile(id));
  }, []);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleInputFocus = () => {
    if (name === "") {
      setName(""); // Ensure that name state is cleared when input is focused
    }
  };
  useEffect(() => {
    setName(userData?.name);
    setreferralID(userData?.referralBy);
    if (userData?.profilePic) {
      setImageSrc(userData?.profilePic);
    } else {
      setImageSrc("/Images/Dashboard/profile-detail.svg");
    }
    setTelegram(userData?.telegramHandle);
    setXhandle(userData?.xHandle);
  }, [userData]);

  useEffect(() => {
    // Get the 'userId' cookie
    const refferUser = Cookies.get("reffer");
    if (refferUser) {
      setUserReffer(refferUser);
    }
  }, []);

  const handleInputChange = (e) => {
    // Regular expression to match Telegram handle
    const regex = /(?:https?:\/\/)?t\.me\/([a-zA-Z0-9_]+)/;
    const match = e.target.value.match(regex);
    if (match) {
      // Extract the handle from the matched string
      const handle = match[1];
      setTelegram(handle);
    } else {
      // If no match found, set the input value as it is
      setTelegram(e.target.value);
    }
  };
  const handleTwitter = (e) => {
    // Regular expression to match Twitter handle
    const regex = /(?:https?:\/\/)?twitter\.com\/(?:#!\/)?@?([^\/\?\s]+)/;
    const match = e.target.value.match(regex);

    if (match) {
      // Extract the handle from the matched string
      const handle = match[1];
      setXhandle(handle);
    } else {
      // If no match found, set the input value as it is
      setXhandle(e.target.value);
    }
  };

  return (
    <>
      {loader && <ScreenLoader />}

      {userLoading?.loading && <ScreenLoader />}
      {/* <SideDrawer showSidebar={true}> */}
      <div className="profile-whole">
        <p className="profile-head">Profile Details</p>
        <div className="profile-div">
          <label htmlFor="file-input">
            <img
              style={{ cursor: "pointer" }}
              src={imageSrc}
              alt="Profile-img"
              className={
                imageSrc !== defaultprofile ? "profile-img" : "d-block"
              }
            />
          </label>
          <input
            id="file-input"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <p className="profile-txt">
            {imageSrc !== defaultprofile ? "" : "Add Profile Photo"}
          </p>
        </div>
        <div className="wallet-profile">
          <p className="wallet-address-head">Wallet Address:</p>
          <p className="wallet-value d-flex align-items-center ">
            {userData?.walletAddress}{" "}
            <img
              src="/Images/copy.svg"
              alt="copy-txt"
              className="ms-2 copyimg"
              // className="copy-img"
              onClick={() => copyToClipboard(userData?.walletAddress)}
            />
          </p>
        </div>

        {/* <div className="profile-div">
            <img src="/Images/Dashboard/profile-detail.svg" alt="Profile-img" />
            <p className="profile-txt">Add Profile Photo</p>
          </div> */}
        <div className="div2 w-100">
          <div className="w-100 div2-r2 ">
            <Form.Group className="referral-group">
              <Form.Label className="input-label">Referral Link</Form.Label>
              <div className="copy-input-contain">
                <Form.Control
                  type="text"
                  placeholder="Enter referral Link"
                  className="referral-input"
                  value={`${userData?.referralId}`}
                  ref={inputRef}
                  disabled={userData?.referralId ? true : false}
                  onChange={(e) => setreferralID(e.target.value)}
                />
                <img
                  src="/Images/copy.svg"
                  alt="copy-txt"
                  className="copy-img"
                  onClick={() => handleReferralCopy(userData?.referralId)}
                />
              </div>
            </Form.Group>
            {userData?.walletAddress ? (
              <Form.Group className="referral-group">
                <Form.Label className="input-label">Wallet Address</Form.Label>
                <div className="copy-input-contain">
                  <Form.Control
                    type="text"
                    placeholder="Enter wallet address"
                    className="referral-input"
                    value={`${userData?.walletAddress.slice(
                      0,
                      8
                    )}....${userData?.walletAddress.slice(-3)}`}
                    ref={walletRef}
                    disabled

                    // onChange={(e) => setwalletAdd(e.target.value)}
                  />
                  <img
                    src="/Images/copy.svg"
                    alt="copy-txt"
                    className="copy-img"
                    onClick={() => copyToClipboard(userData?.walletAddress)}
                  />
                </div>
              </Form.Group>
            ) : (
              <Form.Group className="email-group">
                <Form.Label className="input-label">Wallet Address</Form.Label>

                <Button
                  className="btn-sign-email"
                  onClick={() => setShowAddWallet(true)}
                >
                  Connect Wallet
                </Button>
                <AddWallet
                  show={ShowAddWallet}
                  setModalShows={setShowAddWallet}
                  onHide={() => {
                    setShowAddWallet(false);
                  }}
                />
              </Form.Group>
            )}
          </div>
          <div className="div2-r2  w-100">
            <Form.Group>
              <Form.Label className="input-label">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                className="referral-input"
                onChange={handleNameChange}
                onFocus={handleInputFocus}
                value={name}
                // value={userData?.name ? userData?.name : name}
              />
            </Form.Group>

            {!userData?.email ? (
              <Form.Group
                controlId="exampleForm.ControlInput1 "
                className="email-group"
              >
                <Form.Label className="input-label">Email</Form.Label>

                <Button
                  className="btn-sign-email"
                  onClick={() => setSignInShow(true)}
                >
                  Connect Email
                </Button>
                <SignInEmail
                  show={ShowSignIn}
                  setModalShows={setSignInShow}
                  onHide={() => {
                    setSignInShow(false);
                  }}
                />
              </Form.Group>
            ) : userData?.verified ? (
              <Form.Group controlId="exampleForm.ControlInput1 ">
                <Form.Label className="input-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  className="referral-input"
                  value={userData?.email}
                />
              </Form.Group>
            ) : (
              <Form.Group
                controlId="exampleForm.ControlInput1 "
                className="email-group"
              >
                <Form.Label className="input-label">Email</Form.Label>

                <Button
                  className="btn-sign-email"
                  onClick={() => setNotVerified(true)}
                >
                  Verify Email
                </Button>
                <NotVerified
                  show={notVerified}
                  setModalShows={setNotVerified}
                  onHide={() => {
                    setNotVerified(false);
                  }}
                />
              </Form.Group>
            )}
          </div>
          <div className="div2-r3  w-100">
            <Form.Group>
              <Form.Label className="input-label">Telegram Handle</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter telegram handle"
                className="referral-input"
                value={telegram}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="input-label">X Handle</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter X handle"
                className="referral-input"
                value={xhandle}
                onChange={handleTwitter}
              />
            </Form.Group>
          </div>
        </div>
        <div className="div3-btn">
          <Button className="profile-connect-btn" onClick={addUser}>
            Update
          </Button>
        </div>
      </div>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000} // Auto-hide after 3 seconds
        autohide
        style={{
          position: "absolute",
          top: 80,
          right: 20,
          minWidth: 200,
        }}
      >
        <Toast.Body>Copied Successfully </Toast.Body>
      </Toast>
      {/* </SideDrawer> */}
    </>
  );
}
