import React from "react";
import { useState, useEffect } from "react";
import Signature from "../../../hooks/Web3Connection/userSign";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/Web3Connection/useAuth";
// import { useTheme } from "@/utils/themecontext/ThemeProvider";
import { ErrorToast, SuccessToast } from "../../toast/Toast";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../services/client";
import { Modal } from "react-bootstrap";
import "../SignIn/SignIn.css";
import SignUp from "../SignUp/SignUp";
import ForgetPass from "../Forgetpass/Forgetpass";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import {
  addEmailAddress,
  addEmailAddressGoogle,
  getProfile,
} from "../../../services/redux/middleware/signin";
import ScreenLoader from "../../loader/ScreenLoader";

export default function SignInEmail(props) {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const logingoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const datas = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );

      try {
        const data = {
          id: userId,
          email: datas?.data?.email,
        };
        dispatch(addEmailAddressGoogle(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);

            SuccessToast("Email Added SuccessFully");
            dispatch(getProfile(userId));
            props.setModalShows(false);
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const login = () => {
    try {
      if (!email) {
        ErrorToast("Plese Enter Email");
        return;
      } else if (!password) {
        ErrorToast("Plese Enter Password");
        return;
      }

      const data = {
        id: userId,
        email: email,
        password: password,
      };
      dispatch(addEmailAddress(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);

          SuccessToast("Email Added SuccessFully");
          dispatch(getProfile(userId));
          props.setModalShows(false);
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    setUserId(localStorage.getItem("_id"));
  }, []);

  return (
    <>
      {loader && <ScreenLoader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-whole-signin "
        show={props.show}
        // onHide={() => props.setModalShows(false)}
      >
        <Modal.Header
          style={{ background: "white", color: "black", maxwidth: "680px" }}
          className="main-section-signin"
        >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <img
            src={"/Images/cross.svg"}
            className="close-btn-img"
            onClick={() => props.setModalShows(false)}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: "white",

            borderTop: "none",
            color: "black",
            width: "100%",
            height: "100%",
            justifyContent: "center",
          }}
          className="main-section-signin"
        >
          <div className="Web3modal__heading">
            <h1>Connect Email</h1>
            <p>Please fill your email and password to sign In.</p>
            <div
              onClick={() => {
                logingoogle();
              }}
              className="Web3modal__heading-google google-2"
            >
              <img src="/Images/Signin/google-icon-1.svg" alt="/" />
              <p>Connect with Google</p>
            </div>
          </div>

          <div className="web3modal__text">
            <div className="web3modal__email">
              <h5>Email address</h5>
              <input
                type="email"
                placeholder="Enter your email"
                className="shadow-btn"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="web3modal__email">
              <h5>Password</h5>
              <input
                type="password"
                placeholder="Enter your password"
                className="shadow-btn"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <div className="web3modal__btn">
              <button onClick={()=>{login()}}>Connect Email</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
