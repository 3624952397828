import { connectorsByName } from "../../utils/Web3Connection/web3React/web3React";
import { ErrorToast } from "../../Components/toast/Toast";

const useAuth = () => {

  const login = async (connectorID, id) => {
  try{

    const connector = connectorsByName[connectorID];
    if (connector) {
      if (connectorID === "injected") {
        await connector.activate(id);
      } else {
        
        await connector.activate();
      }
    } else {
      
      ErrorToast("Can't find connector", "The connector config is wrong");
    }
  }catch(error){
    console.log("error in login")
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const logout = async (connectorID) => {
    const connector = connectorsByName[connectorID];
    if (connector) {
      if (connector?.deactivate) {
        await connector.deactivate();
      } else {
        await connector.resetState();
      }
      // await connector.deactivate()
    } else {
      ErrorToast("Can't find connector", "The connector config is wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return { login, logout };
};

export default useAuth;
