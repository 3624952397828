import { Modal } from "react-bootstrap";
import "./AddWallet.css";

import { useState, useEffect } from "react";
import Signature from "../../../hooks/Web3Connection/userSign";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/Web3Connection/useAuth";
// import { useTheme } from "@/utils/themecontext/ThemeProvider";
import { ErrorToast, SuccessToast } from "../../toast/Toast";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../services/client";
import { addWalletAddress, getProfile } from "../../../services/redux/middleware/signin";
import ScreenLoader from "../../loader/ScreenLoader";
import { useDispatch } from "react-redux";

export default function AddWallet(props) {
  const { account, chainId } = useWeb3React();
  const { userSign } = Signature();
  const [log, setLog] = useState(false);
  const { login } = useAuth();
  const [loader, setLoader] = useState();
  const [userId, setUserId] = useState();
  const dispatch=useDispatch()
  //   const theme = useTheme();

  const trustWallet = async () => {
    try {
      props.setModalShows(false);
      await login("walletconnect", 1);
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const connectMetaMask1 = async () => {
    try {
      props.setModalShows(false);

      await login("injected", 1);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  async function loginweb3() {
    setLoader(true);

    const data = {
      id: userId,
      walletAddress: account,
    };

    try {
      dispatch(addWalletAddress(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          
          dispatch(getProfile(userId))
          // localStorage.setItem("_id", res?.payload?.data?._id);
          // localStorage.setItem("token", res?.payload?.token);

          // if (res?.payload?.data?.profileUpdate) {
          //   navigate("/");
          // } else {
          //   navigate("/Dashboard/Profile-Detail");
          // }
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const loginUser = async () => {
    if (account) {
      const res0 = await userSign(account);
      Cookies.set("signature", res0, {
        secure: true,
      });
      if (account && res0) {
        loginweb3();
        SuccessToast("User Signature Successfull !");
      }
    } else {
      ErrorToast("Wallet not Connected");
    }
  };

  useEffect(() => {
    if (account && log) {
      loginUser();
      setLog(false);
    }
  }, [account, log]);
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    setUserId(userId);
  }, [account, log]);

  return (
    <>
      {loader && <ScreenLoader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-whole-web"
        show={props.show}
        onHide={() => props.setModalShows(false)}
      >
        <Modal.Header
          style={{ background: "white", color: "black" }}
          className="main-section-wallet"
        >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <img
            src={"/Images/cross.svg"}
            className="close-btn-img"
            onClick={() => props.setModalShows(false)}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: "white",

            borderTop: "none",
            color: "black",
          }}
          className="main-section-wallet"
        >
          <div>
            <p className="connect-p">Add your Wallet</p>
            <div className="subText-top-div">
          {/* <p className="subText">
            Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie,  dictum est a, mattis tellus. Sed dignissim,
            metus nec fringilla accumsan, risus sem  sollicitudin lacus,
            ut interdum tellus elit sed risus.
          </p> */}
          </div>
            <div className="choose-txt">
              <p
              // fontSize={"12px"}
              // fontWeight={"600"}
              // lineHeight={"16.26px"}
              // color={"#656565"}
              >
                Kindly choose from options below:
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center meta-cards">
            <div className="meta-diff">
              <img
                className="wallet-main-img"
                alt="connect wallet"
                src={"/Images/walletConnect.svg"}
                onClick={trustWallet}
              />
              <p className="wallet-img_txt">WalletConnect</p>
            </div>
              <div className="meta-common">
                <img
                  className="wallet-main-img"
                  src={"/Images/metamask.svg"}
                  alt="polygon"
                  onClick={connectMetaMask1}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
