import contractAbi from "../../utils/Contracts/contractAbi.json";
import { MintContract } from "../../utils/Contracts/ContractAddress";
import { useCallback } from "react";
export const useCheckCurrentPhase = () => {
  const checkcurrentPhaseHook = useCallback(async (customweb3) => {
    const contract = new customweb3.eth.Contract(contractAbi, MintContract);
    try {
      const estimatedGas = await contract.methods.whitelistPhase().estimateGas();

      const details = await contract.methods.whitelistPhase().call({
        gas: estimatedGas,
      });

      return details;
    } catch (error) {
      console.log(error, "errpr");
      throw error;
    }
  });
  return { checkcurrentPhaseHook: checkcurrentPhaseHook };
};
export default useCheckCurrentPhase;
