import React, { useState, useEffect } from 'react';
import "./claimprofits.css"

const TimerButton = ({ buttonText, onClick, createdAt }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(createdAt));
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    let intervalId;

    if (timerActive && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timerActive, timeLeft]);

  const handleButtonClick = () => {
    setTimerActive(true);
    onClick(); // Call the provided onClick function
  };

  return (
    <div>
      <button onClick={handleButtonClick} disabled={timerActive} className={timerActive ? "btn-active" : "btn-inactive"} >
        {timerActive ? formatTimeLeft(timeLeft) : buttonText}
      </button>
    </div>
  )
}

function calculateTimeLeft(createdAt) {
  const expiryDate = new Date(createdAt);
  expiryDate.setFullYear(expiryDate.getFullYear() + 1); // Add one year to createdAt date
  const currentTime = new Date();
  const timeDifference = expiryDate.getTime() - currentTime.getTime();
  return Math.floor(timeDifference / 1000); // Convert milliseconds to seconds
}

function formatTimeLeft(totalSeconds) {
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
}

export default TimerButton;
