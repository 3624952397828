import React from "react";
import "./listedNftnext.css";

const ListedNFTnext = () => {
  return (
    <div className="ListedNFTnext__main">
      <div className="ListedNFTnext__main-left">
        <img src="/Images/ListedNFT/NFTImg.svg" alt="/" />
        <div className="ListedNFTnext__main-left-body">
          <img src="/Images/ListedNFT/ether.svg" alt="/" />
          <div className="ListedNFTnext__main-left-body-right">
            <img src="/Images/ListedNFT/icon.svg" alt="/" />
            <p className="ListedNFTnext__main-left-body-right-txt">1</p>
            <img src="/Images/ListedNFT/heart.svg" alt="/" />
          </div>
        </div>
      </div>
      <div className="ListedNFTnext__main-right">
        <div className="ListedNFTnext__main-right-blueTick">
          <p>web3 Fund</p>
          <img src="/Images/ListedNFT/tick.svg" alt="/" />
        </div>
        <h4 className="ListedNFTnext__main-right-head">M5-24 20K</h4>
        <p className="ListedNFTnext__main-right-head-txt">
          Owned by <span>KEVIN</span>
        </p>
        <div className="ListedNFTnext__main-right-body">
          <p className="ListedNFTnext__main-right-body-txt"># 6,905</p>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <img src="/Images/ListedNFT/eye.svg" alt="/" />
            <p className="ListedNFTnext__main-right-body-text">34 Views</p>
          </div>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <img src="/Images/ListedNFT/heart2.svg" alt="/" />
            <p className="ListedNFTnext__main-right-body-text">1 Favorite</p>
          </div>
        </div>
        <div className="ListedNFTnext__main-right-body-bottom">
          <div className="ListedNFTnext__main-right-body-sale">
            <img src="/Images/ListedNFT/clock.svg" alt="/" />
            <p>Sale ends in June 17, 2024 at 7:30 AM</p>
          </div>
          <div className="ListedNFTnext__main-right-body-sale-head">
            <p>Current price</p>
            <h6>
              0.2179 ETH <span className="ListedNFTnext__main-right-body-sale-head-span">$641.42</span>{" "}
            </h6>
          </div>
          <div className="ListedNFTnext__btn">
            <button className="ListedNFTnext__main-right-body-sale-btn1">Buy Now</button>
            <button className="ListedNFTnext__main-right-body-sale-btn2">Make Offer</button>
          </div>
          <p className="bottom-text">
            Supports creator{" "}
            <span>
              This listing is paying the collection creator their suggested
              creator earnings.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ListedNFTnext;
