import React from "react";
import { useState, useEffect } from "react";
import Signature from "../../../hooks/Web3Connection/userSign";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/Web3Connection/useAuth";
// import { useTheme } from "@/utils/themecontext/ThemeProvider";
import { ErrorToast, SuccessToast } from "../../toast/Toast";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../services/client";
import { Modal } from "react-bootstrap";
import "./SignIn.css";
import SignUp from "../SignUp/SignUp";
import ForgetPass from "../Forgetpass/Forgetpass";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { signin } from "../../../services/redux/middleware/signin";
import ScreenLoader from "../../loader/ScreenLoader";
import { logoutFun } from "../../../services/redux/reducer/getLogout";

export default function SignIn(props) {
  const [showSignUp, setshowSignup] = useState(false);
  const [showForgetPass, setshowForgetPass] = useState(false);
  const { account, chainId } = useWeb3React();
  const navigate = useNavigate();
  const { userSign } = Signature();
  const [log, setLog] = useState(false);
  const { login } = useAuth();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [userReffer, setUserReffer] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  //   const theme = useTheme();

  const trustWallet = async () => {
    try {
      props.setModalShows(false);
      await login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      ErrorToast(err);
      console.log("err", err);
    }
  };

  const connectMetaMask1 = async () => {
    try {
      // props.setModalShows(false);

      await login("injected", 1);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      ErrorToast(err);

      console.log("err", err);
    }
  };

  const formValidation = () => {
    if (!email) {
      ErrorToast("Please Enter Email");
      return false;
    } else if (!password) {
      ErrorToast("Please Enter Password");
      return false;
    }
  };
  async function loginweb2() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        google: false,
        email: email,
        password: password,
      };
      dispatch(signin(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          localStorage.setItem("_id", res?.payload?.data?._id);
          localStorage.setItem("token", res?.payload?.token);
          localStorage.setItem(
            "profileupdate",
            res?.payload?.data?.profileUpdate
          );
          dispatch(logoutFun(true));

          if (res?.payload?.data?.profileUpdate) {
            navigate("/");
          } else {
            navigate("/Dashboard/Profile-Detail");
          }
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const logingoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const datas = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );

      try {
        const data = {
          email: datas?.data?.email,
          google: true,
          referralBy:userReffer
        };
        dispatch(signin(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            localStorage.setItem("_id", res?.payload?.data?._id);
            localStorage.setItem("token", res?.payload?.token);
            localStorage.setItem(
              "profileupdate",
              res?.payload?.data?.profileUpdate
            );
            dispatch(logoutFun(true));

            SuccessToast("Sign In Successfully");
            if (res?.payload?.data?.profileUpdate) {
              navigate("/");
            } else {
              navigate("/Dashboard/Profile-Detail");
            }
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  async function loginweb3(data) {
    setLoader(true);
    try {
      dispatch(signin(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          localStorage.setItem("_id", res?.payload?.data?._id);
          localStorage.setItem("token", res?.payload?.token);
          localStorage.setItem(
            "profileupdate",
            res?.payload?.data?.profileUpdate
          );
          dispatch(logoutFun(true));

          if (res?.payload?.data?.profileUpdate) {
            navigate("/");
          } else {
            navigate("/Dashboard/Profile-Detail");
          }
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const loginUser = async () => {
    try {
      if (account) {
        const res0 = await userSign(account);
        Cookies.set("signature", res0, {
          secure: true,
        });
        if (account && res0) {
          const data = {
            walletAddress: account,
            referralBy:userReffer
          };
          loginweb3(data);
          SuccessToast("User Signature Successfull !");
        }
      } else {
        ErrorToast("Wallet not Connected");
      }
    } catch (e) {
      ErrorToast(e);
    }
  };

  useEffect(() => {
    if (account && log) {
      loginUser();
      setLog(false);
    }
  }, [account, log]);
  useEffect(() => {
    // Get the 'userId' cookie
    const refferUser = Cookies.get("reffer");
    if (refferUser) {
      setUserReffer(refferUser);
    }
  }, []);
  return (
    <>
      {loader && <ScreenLoader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-whole-signin"
        show={props.show}
        // onHide={() => props.setModalShows(false)}
      >
        <Modal.Header
          style={{ background: "white", color: "black", maxwidth: "680px" }}
          className="main-section-signin"
        >
          {/* <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        <img
          src={"/Images/cross.svg"}
          className="close-btn-img"
          onClick={() => props.setModalShows(false)}
        /> */}
        </Modal.Header>
        <Modal.Body
          style={{
            background: "white",

            borderTop: "none",
            color: "black",
            width: "100%",
            height: "100%",
            justifyContent: "center",
          }}
          className="main-section-signin"
        >
          <div className="Web3modal__heading">
            <h1>Sign In</h1>
            <p>Please fill your email and password to sign In.</p>
            <div
              onClick={() => {
                logingoogle();
              }}
              className="Web3modal__heading-google "
            >
              <img src="/Images/Signin/google-icon-1.svg" alt="/" />
              <p>Sign in with Google</p>
            </div>
            <div className=" align-items-center justify-content-center  meta-cards ">
              <div className="meta-new " onClick={connectMetaMask1}>
                <img src="/Images/Metmask.svg" alt="Metamask" />
              </div>
              <div className="meta-connect-new" onClick={trustWallet}>
                <img src="/Images/walletconnect-img.svg" alt="Wallet-connect" />
              </div>
            </div>
          </div>

          <div className="web3modal__text">
            <div className="web3modal__email email-signin-input">
              <h5>Email address</h5>
              <input
                type="text"
                placeholder="Enter your email"
                className="shadow-btn"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="web3modal__email password-signin-input">
              <h5>Password</h5>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="shadow-btn "
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button onClick={togglePasswordVisibility}>
                {showPassword ? (
                  <img src="/Images/Signin/eye.svg" />
                ) : (
                  <img src="/Images/Signin/eyeoff.svg" />
                )}
              </button>
            </div>
            <div className="web3modal__btn">
              <button
                onClick={() => {
                  loginweb2();
                }}
              >
                Sign In
              </button>
              <p
                className="p2"
                onClick={() => {
                  navigate("/forget-password");
                }}
              >
                Forgot password
              </p>
              <p style={{ paddingTop: "10px" }} className="for_signin">
                Don’t have an account?{" "}
                <span
                  onClick={() => {
                    navigate("/Signup");
                  }}
                >
                  Sign up today
                </span>
              </p>
            </div>
            <SignUp
              show={showSignUp}
              setModalShows={setshowSignup}
              onHide={() => {
                setshowSignup(false);
              }}
            />
            <ForgetPass
              show={showForgetPass}
              setModalShows={setshowForgetPass}
              onHide={() => {
                setshowForgetPass(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
