import { Modal } from "react-bootstrap";
import "./SignUp.css";

import { useState, useEffect } from "react";
import Signature from "../../../hooks/Web3Connection/userSign";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/Web3Connection/useAuth";
// import { useTheme } from "@/utils/themecontext/ThemeProvider";
import { ErrorToast, SuccessToast } from "../../toast/Toast";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../services/client";
import ForgetPass from "../Forgetpass/Forgetpass";
import SignIn from "../SignIn/SignIn";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signup } from "../../../services/redux/middleware/signin";
import ScreenLoader from "../../loader/ScreenLoader";
import { useGoogleLogin } from "@react-oauth/google";
import { logoutFun } from "../../../services/redux/reducer/getLogout";
// import NotVerified from "../NotVerified/NotVerified";
import Verification from "../Verification/Verificationmodal";

export default function SignUp(props) {
  const [showForgetPass, setshowForgetPass] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [userReffer, setUserReffer] = useState("");
  const { account, chainId } = useWeb3React();
  const { userSign } = Signature();
  const [log, setLog] = useState(false);
  const { login } = useAuth();
  //   const theme = useTheme();
  // const [showVerified , setShowVerified] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const trustWallet = async () => {
    try {
      props.setModalShows(false);
      await login("walletconnect", 1);
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const connectMetaMask1 = async () => {
    try {
      // props.setModalShows(false);

      await login("injected", 1);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setLog(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  const formValidation = () => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    if (!email) {
      ErrorToast("Please Enter Email");
      return false;
    } else if (!password) {
      ErrorToast("Please Enter Password");
      return false;
    } else if (!passwordRegex.test(password)) {
      ErrorToast("Password must contain at least one letter, one number, one special character, and be at least 8 characters long.");
      return false;
    }
    return true;
  };
  async function loginweb2() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        google: false,
        email: email,
        password: password,
        referralBy:userReffer
      };
      dispatch(signup(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          SuccessToast(
            "Sign Up Successfully please check your mail for verification"
          );
          
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const logingoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const datas = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );

      try {
        const data = {
          email: datas?.data?.email,
          google: true,
          referralBy:userReffer
        };
        dispatch(signup(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            localStorage.setItem("_id", res?.payload?.data?._id);
            localStorage.setItem("token", res?.payload?.token);
            localStorage.setItem(
              "profileupdate",
              res?.payload?.data?.profileUpdate
            );
            dispatch(logoutFun(true))

            SuccessToast("Singup Successfully");
            if (res?.payload?.data?.profileUpdate) {
              navigate("/");
            } else {
              navigate("/Dashboard/Profile-Detail");
            }
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  async function loginweb3(data) {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      dispatch(signup(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          localStorage.setItem("_id", res?.payload?.data?._id);
          localStorage.setItem("token", res?.payload?.token);
          localStorage.setItem(
            "profileupdate",
            res?.payload?.data?.profileUpdate
          );

          if (res?.payload?.data?.profileUpdate) {
            navigate("/");
          } else {
            navigate("/Dashboard/Profile-Detail");
          }
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const loginUser = async () => {
    if (account) {
      const res0 = await userSign(account);
      Cookies.set("signature", res0, {
        secure: true,
      });
      if (account && res0) {
        // loginweb3();
        SuccessToast("User Signature Successfull !");
        const data = {
          walletAddress: account,
          referralBy:userReffer
        };
        await loginweb3(data);
      }
    } else {
      ErrorToast("Wallet not Connected");
    }
  };
  useEffect(() => {
    // Get the 'userId' cookie
    const refferUser = Cookies.get("reffer");
    if (refferUser) {
      setUserReffer(refferUser);
    }
  }, []);

  useEffect(() => {
    if (account && log) {
      loginUser();
      setLog(false);
    }
  }, [account, log]);

  return (
    <>
      {loader && <ScreenLoader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-whole-signup"
      >
        <Modal.Header
          style={{ background: "white", color: "black", maxwidth: "680px" }}
          className="main-section-signup"
        >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          {/* <img
          src={"/Images/cross.svg"}
          className="close-btn-img"
          onClick={props.onHide}
        /> */}
        </Modal.Header>
        <Modal.Body className="main-section-signup">
          <div className="Web3modal__heading">
            <h1>Sign Up</h1>
            <p>Please fill your email and password to sign up.</p>
            <div
              onClick={() => {
                logingoogle();
              }}
              className="Web3modal__heading-google"
            >
              <img src="/Images/Signin/google-icon-1.svg" alt="/" />
              <p>Sign up with Google</p>
            </div>
          </div>

          <div className=" align-items-center justify-content-center  meta-cards ">
            <div className="meta-new " onClick={connectMetaMask1}>
              <img src="/Images/Metmask.svg" alt="Metamask" />
            </div>
            <div className="meta-connect-new" onClick={trustWallet}>
              <img src="/Images/walletconnect-img.svg" alt="Wallet-connect" />
            </div>
          </div>

          <div className="web3modal__text">
            <div className="web3modal__signup-email email-input">
              <h5>Email address</h5>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                placeholder="Enter your email"
                className="shadow-btn"
              />
            </div>
            <div className="web3modal__signup-email password-input">
              <h5>Password</h5>
              <input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="shadow-btn"
              />
              <button onClick={togglePasswordVisibility}>
                {showPassword ? (
                  <img src="/Images/Signin/eye.svg" />
                ) : (
                  <img src="/Images/Signin/eyeoff.svg" />
                )}
              </button>
            </div>
            <div className="web3modal__btn">
              <button
                onClick={() => {
                  loginweb2();
                }}
              >
                Sign Up
              </button>
              <p onClick={() => {
                    navigate("/login");
                  }}>
                Or{" "}
                <span
                  
                >
                  {" "}
                  Login
                </span>{" "}
                instead
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
