import React from "react";
import { useState, useEffect } from "react";
import Signature from "../../../hooks/Web3Connection/userSign";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/Web3Connection/useAuth";
// import { useTheme } from "@/utils/themecontext/ThemeProvider";
import { ErrorToast, SuccessToast } from "../../toast/Toast";
import Cookies from "js-cookie";
import axios from "axios";
import { API_URL } from "../../../services/client";
import { Modal } from "react-bootstrap";
import "./Newpass.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { newPassword } from "../../../services/redux/middleware/signin";
import { useDispatch } from "react-redux";

export default function Newpass(props) {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formValidation = () => {
    if (!password) {
      ErrorToast("Please Enter Password");
      return false;
    } else if (!confirmPassword) {
      ErrorToast("Please Confirm Password");
      return false;
    } else if (password !== confirmPassword) {
      ErrorToast("Password Not match");
      return false;
    }
  };
  async function passwordUpdate() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        token: id,
        password: password,
      };
      dispatch(newPassword(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          navigate("/login");
          SuccessToast("Password Updated Successfully");
        } else {
          setLoader(false);

          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-whole"
        show={props.show}
        // onHide={() => props.setModalShows(false)}
      >
        <Modal.Body
          style={{
            background: "white",

            borderTop: "none",
            color: "black",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            padding: "40px 0px  5px 0px",
            borderRadius: "30px",
          }}
          className="main-section-wallet"
        >
          <div className="forgetpass">
            <div className="forgetpass__main-new newpass-main">
              <h1>Add New Password</h1>
              <p>Please enter and confirm your new password below.</p>
              <div className="newpass-form">
                <div className="newpass-email-label">
                  <h5>Password</h5>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    className="newpass-input"
                  />
                  <button onClick={togglePasswordVisibility} className =" forgetpass__eyebtn">
                    {showPassword ? (
                      <img src="/Images/Signin/eye.svg" />
                    ) : (
                      <img src="/Images/Signin/eyeoff.svg" />
                    )}
                  </button>
                </div>
                <div className="newpass-email-label">
                  <h5>Confirm Password</h5>
                  <input
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    type="text"
                    placeholder="Confirm password"
                    className="newpass-input"
                  />
                  {password && password === confirmPassword && (
                    <p className="password-match">Passwords match</p>
                  )}
                  <button onClick={togglePasswordVisibility} className =" forgetpass__eyebtn1">
                    {showPassword ? (
                      <img src="/Images/Signin/eye.svg" />
                    ) : (
                      <img src="/Images/Signin/eyeoff.svg" />
                    )}
                  </button>
                  <button className = "forgetpass__newpass-submit"
                    onClick={() => {
                      passwordUpdate();
                    }}
                    style={{ marginBottom: "30px" }}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
