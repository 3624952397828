import React, { useState } from "react";
import SignUp from "../../../Components/modal/SignUp/SignUp";
import "./SignUpPg.css";
export default function SignUpPg() {
  const [shows, setModalsShow] = useState(true);

  return (
    <>
      <div className="SignUp-PG">
        <SignUp show={shows} setModalShows={setModalsShow} />
      </div>
    </>
  );
}
