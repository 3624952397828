import "./Drawer.css";
import { Container, InputAdornment, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useAuth from "../../hooks/Web3Connection/useAuth";
import Web3Modal from "../modal/Web3modal/Web3Modal";
import Avatar from "@mui/material/Avatar";
import { socket } from "../../utils/socket";
import notificationaudio from "../../assets/audio/notification_sound.mp3";

import { getProfileInfo } from "../../services/redux/middleware/signin";

import { useWeb3React } from "@web3-react/core";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Menu, MenuItem, makeStyles } from "@mui/material";

import { useMediaQuery } from "react-responsive";
import { Logout, X } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getProfileImage } from "../../services/redux/middleware/signin";
import {
  getUserHistory,
  readUserHistory,
} from "../../services/redux/middleware/getNft";
import { logoutFun } from "../../services/redux/reducer/getLogout";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // zIndex:9999999999,
    height: "100%",
    overflowX: "hidden",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      fontFamily: "Montserrat",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  zIndex: "2",
}));

const AvatarTopDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minWidth: 768,
  },
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",

    gap: theme.spacing(2),
  },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  padding: "26px 24px",
  borderBottom: "none",
  // zIndex: "2",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const themeMui = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

export default function SideDrawer({ children, showSidebar }) {
  const dispatch = useDispatch();
  const userDataInfo = useSelector(
    (state) => state?.profileInfo?.userProfile?.data
  );
  console.log("this is user data info", userDataInfo);
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    dispatch(getProfileInfo(userId));
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: "(max-width: 1064px)",
  });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedotherItem, setSelectedOtherItem] = useState(null);
  const historyData = useSelector((state) => state?.history?.userHistory?.data);

  const handleListItemClick = (index) => {
    setSelectedOtherItem(null);
    setSelectedItem(index);
  };
  const handleOtherListItemClick = (index) => {
    setSelectedItem(null);
    setSelectedOtherItem(index);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
  };

  const handleSignIn = () => {
    localStorage.clear();
    dispatch(logoutFun(false));
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: "Dashboard", path: "/", disabled: false },
    { text: "Account", path: "/Dashboard/UserProfile", disabled: false },
    { text: "Buy", path: "/Dashboard/MintScreen" },
    { text: "Latest News", path: "/Dashboard/Planner", disabled: true },
    { text: "Trade Web3Fund NFT", path: "/Dashboard/Report", disabled: true },
    { text: "Chat", path: "/Dashboard/Admin", disabled: true },
  ];
  const dashicons = [
    "/Images/Dashboard/dashboard.svg",
    "/Images/Dashboard/referrals.svg",
    "/Images/shopping-cart.svg",
    "/Images/Dashboard/latestnews.svg",
    "/Images/Dashboard/tradeweb3.svg",
    "/Images/Dashboard/chat.svg",
  ];
  const dashiconsActive = [
    "/Images/Dashboard/discover.svg",
    "/Images/Dashboard/profile-2user.svg",
    "/Images/Dashboard/shopping-cart.svg",
  ];
  const OthermenuItems = [
    { text: "Help", path: "https://t.me/Web3FundAdmin" },
    { text: "Feedback", path: "/https://t.me/Web3FundAdmin" },
  ];
  const otherdashicons = [
    "/Images/Dashboard/help.svg",
    "/Images/Dashboard/Feedback.svg",
  ];

  let users = JSON.parse(localStorage.getItem("user"));

  let filteredMenuItems = [];
  let filteredOtherMenuItems = [];

  if (users?.role === 1) {
    filteredMenuItems = menuItems.filter((item) =>
      ["Home", "File Manager", "Admin"].includes(item.text)
    );
  } else {
    filteredMenuItems = menuItems.filter((item) => item.text !== "Admin");
    filteredOtherMenuItems = OthermenuItems.filter(
      (item) => item.text !== "Admin"
    );
  }

  let audio = new Audio(notificationaudio);

  // for connect wallet
  const [wallet, SetWallet] = useState(false);
  const { account, chainId } = useWeb3React();
  const userImage = useSelector(
    (state) => state?.profileImage?.profileImage?.data?.image
  );
  const { logout } = useAuth();
  const connectorId =
    typeof window !== "undefined" ? localStorage.getItem("connectorId") : null;
  async function handleConnect() {
    if (!account) {
      SetWallet(true);
    } else {
      await logout(connectorId);
      localStorage.clear();
      dispatch(logoutFun(false));

      navigate("/login");
    }
  }

  async function Logout() {
    localStorage.clear();
    await logout(connectorId);
    navigate("/login");
  }
  async function readeNotification(id) {
    const data = {
      id: id,
    };
    dispatch(readUserHistory(data)).then((res) => {
      if (res.payload.status === 200) {
        const userId = localStorage.getItem("_id");
        const data = {
          id: userId,
        };

        dispatch(getUserHistory(data));
      }
    });
  }
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    dispatch(getProfileImage(userId));
  }, []);
  function timeAgo(date) {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  const getNotification = async (data) => {
    audio.play();
    dispatch(getUserHistory(data));
  };
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    const data = {
      id: userId,
      page: 1,
    };
    socket.on("connect", () => {
      socket.emit("join", userId);
    });
    if (userId) {
      socket.on("loggedIn", () => getNotification(data));
      socket.on("signedUp", () => getNotification(data));
      socket.on("mint-nft", () => getNotification(data));
    }
    return () => {
      socket.disconnect();
    };
  }, []);
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    const data = {
      id: userId,
      page: 1,
    };
    dispatch(getUserHistory(data));
  }, []);

  const hasUnread =
    historyData?.userHistory &&
    historyData?.userHistory?.some((item) => item && item?.read === false);

  return (
    <>
      <ThemeProvider theme={themeMui}>
        {showSidebar && (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{ boxShadow: "none" }}>
              <Toolbar style={{ position: "relative", zIndex: "2" }}>
                {" "}
                <IconButton
                  color="#0000"
                  aria-label="open drawer"
                  onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    position: "absolute",
                    zIndex: 999,
                  }}
                >
                  <MenuIcon
                    style={{ position: "relative", zIndex: 999 }}
                    color={"black"}
                  />
                </IconButton>
                <div className="d-flex flex-column">
                  <div className="display-none-top">
                    <p className={open ? "welcome-txt" : "welcome-txt-space"}>
                      {userDataInfo?.user?.name ? (
                        <>Welcome Back {userDataInfo?.user?.name}! 👋</>
                      ) : userDataInfo?.user?.walletAddress ? (
                        <>
                          Welcome Back{" "}
                          {`${userDataInfo?.user?.walletAddress.slice(
                            0,
                            8
                          )}....${userDataInfo?.user?.walletAddress.slice(-3)}`}
                          ! 👋
                        </>
                      ) : (
                        <>
                          Welcome Back{" "}
                          {`${userDataInfo?.user?.email.slice(
                            0,
                            8
                          )}....${userDataInfo?.user?.email.slice(-3)}`}
                          ! 👋
                        </>
                      )}
                    </p>
                  </div>

                  <AvatarTopDiv style={{ boxShadow: "none", zIndex: 1 }}>
                    <div
                      id="basic-button"
                      className="Avatar-top-div"
                      aria-controls={opens ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={opens ? "true" : undefined}
                    >
                      <div className="notify-search">
                        <div className="setting-notify">
                          {/* <div>
                            <img
                              src="/Images/Dashboard/notification.svg"
                              alt="notification"
                            />
                          </div> */}
                          <div className="nav__Right">
                            {/* <img src={Search} alt="Logo" /> */}
                            <Dropdown className="navbar__notification">
                              <Dropdown.Toggle className="navbar__notification__dropdown">
                                {hasUnread ? (
                                  <img
                                    src="/Images/Dashboard/notification.svg"
                                    alt="notification"
                                  />
                                ) : (
                                  <img
                                    src="/Images/Dashboard/unreadNoti.svg"
                                    alt="notification"
                                  />
                                )}
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                style={{ background: "white", color: "red" }}
                              >
                                <h2 className="navbar__notification__dropdown__title">
                                  Notifications
                                </h2>
                                <div>
                                  {historyData?.userHistory?.length > 0 &&
                                    historyData?.userHistory?.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            <Dropdown.Item key={index} href="">
                                              <div className="dot-heading">
                                                {!item?.read && (
                                                  <div className="blue-dot"></div>
                                                )}
                                                <div className=" w-100">
                                                  <div>
                                                    <p className="notify-heading">
                                                      {item?.action}
                                                    </p>
                                                    <p className="notify-subtxt">
                                                      {/* {item?.description} */}
                                                      {item?.description &&
                                                      item?.description.length >
                                                        36 ? (
                                                        <>
                                                          {item?.description.slice(
                                                            0,
                                                            36
                                                          )}
                                                          ...
                                                          <span
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {item?.description.slice(
                                                              36
                                                            )}
                                                          </span>
                                                        </>
                                                      ) : (
                                                        item?.description
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="notify-txt-btm">
                                                    <p className="hour-txt">
                                                      {timeAgo(item.createdAt)}
                                                    </p>
                                                    {!item?.read && (
                                                      <p
                                                        onClick={() => {
                                                          readeNotification(
                                                            item?._id
                                                          );
                                                        }}
                                                        className="read-txt"
                                                      >
                                                        Mark as read
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </Dropdown.Item>
                                          </>
                                        );
                                      }
                                    )}
                                  {/* <Dropdown.Item href="">
                                   
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                 
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                   
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                    <div className="navbar__notification__dropdown__container__inner">
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                  
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                  
                                  </Dropdown.Item>
                                  <Dropdown.Item href="">
                                   
                                      <div className="dot-heading">
                                        <div className="blue-dot"></div>
                                        <div className=" w-100">
                                          <div>
                                            <p className="notify-heading">
                                              Lorem ipsum
                                            </p>
                                            <p className="notify-subtxt">
                                              Lorem ipsum dolor sit amet
                                              consectetur
                                            </p>
                                          </div>
                                          <div className="notify-txt-btm">
                                            <p className="hour-txt">1h ago</p>
                                            <p className="read-txt">
                                              Mark as read
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    
                                  </Dropdown.Item> */}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="vertical-line"></div>

                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <Avatar
                            onClick={() =>
                              navigate("/Dashboard/Profile-Detail")
                            }
                            alt="Profile Picture"
                            src={
                              userDataInfo?.user?.profilePic
                                ? userDataInfo?.user?.profilePic
                                : "/Images/User Profile/profile-user.svg"
                            }
                            // src={users?.profilePic}
                            sx={{
                              width: 40.5,
                              height: 40.5,
                              cursor: "pointer",
                            }}
                            className="avatar-img"
                          />
                          {!userImage && (
                            <img
                              src="/Images/!.svg"
                              alt="Alert"
                              style={{
                                position: "absolute",
                                top: "9px",
                                right: 0,
                                transform: "translate(50%, -50%)",
                              }}
                            />
                          )}
                        </div>
                        <div
                          onClick={() => {
                            handleConnect();
                          }}
                          eventKey={2}
                        >
                          <button className="btn-wallet">
                            {account ? "Disconnect" : "Connect"}
                          </button>
                        </div>
                      </div>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={opens}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      ></Menu>
                    </div>
                  </AvatarTopDiv>
                </div>
                {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
              </Toolbar>
            </AppBar>
            {!isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: " #fff",
                  },
                }}
                style={{ position: "relative" }}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  zIndex: open ? 0 : -1,

                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    zIndex: open ? 0 : -1,
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <DrawerHeader>
                  <img
                    src="/Images/Dashboard/web3fund-dash-logo.svg"
                    // width={"30px"}
                    // height={"30px"}
                    className="ms-3"
                    alt="Logo"
                  />
                  <IconButton
                    onClick={handleDrawerClose}
                    style={{ color: "#000" }}
                  >
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List className="List-div">
                  <p className="items-main-heading">General Menu</p>
                  {filteredMenuItems?.map(({ text, path, disabled }, index) => (
                    <ListItem
                      selected={index === selectedItem}
                      key={text}
                      // disabled={disabled}
                      disablePadding
                      divider={false}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "22.4px",
                      }}
                    >
                      <ListItemButton
                        component={Link}
                        disabled={disabled}
                        to={path}
                        onClick={() => handleListItemClick(index)}
                        selected={index === selectedItem}
                        className={
                          index === selectedItem
                            ? "list-item-active"
                            : "list-item"
                        }
                        sx={{
                          "& .MuiListItemText-root": {
                            fontSize: "14px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        <ListItemIcon
                          onClick={() => handleListItemClick(index)}
                          minWidth={"24px"}
                          className="list-icons"
                          selected={index === selectedItem}
                          sx={{
                            "& ..MuiListItemIcon-root": {
                              minWidth: "24px",
                            },
                          }}
                        >
                          {" "}
                          {index === selectedItem ? (
                            <img
                              src={dashiconsActive[index]}
                              alt={`Icon ${index + 1}`}
                            />
                          ) : (
                            <img
                              src={dashicons[index]}
                              alt={`Icon ${index + 1}`}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          selected={index === selectedItem}
                          className={
                            index === selectedItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          primary={text}
                        />
                        {disabled && (
                          <img src="/Images/clock.svg" alt={`Disabled Image`} />
                        )}{" "}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Divider />
                {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}

                <List className="List-div">
                  <p className="items-main-heading">Other Menu</p>

                  <div>
                    {filteredOtherMenuItems?.map(({ text, path }, index) => (
                      <ListItem
                        key={text}
                        disablePadding
                        selected={index === selectedotherItem}
                      >
                        <ListItemButton
                          component={Link}
                          // to={path}
                          selected={index === selectedotherItem}
                          onClick={() => {
                            handleOtherListItemClick(index);
                            window.open(path, "_blank");
                          }}
                          className={
                            index === selectedotherItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          sx={{
                            "& .MuiListItemText-root": {
                              fontSize: "14px",
                            },
                            "& .MuiListItemText-primary": {
                              fontSize: "14px",
                            },
                          }}
                        >
                          <ListItemIcon
                            minWidth={"24px"}
                            className="list-icons"
                            selected={index === selectedotherItem}
                            sx={{
                              "& ..MuiListItemIcon-root": {
                                minWidth: "24px",
                              },
                            }}
                          >
                            <img
                              src={otherdashicons[index]}
                              alt={`Icon ${index + 1}`}
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={
                              index === selectedotherItem
                                ? "list-item-active"
                                : "list-item"
                            }
                            primary={text}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </div>
                </List>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Logout();
                  }}
                  className="Logout-div"
                >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">logout</p>
                </div>
              </Drawer>
            )}

            {isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#fff",
                  },
                }}
                sx={{
                  width: 250,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              >
                <DrawerHeader>
                  <img
                    src="/Images/Dashboard/web3fund-dash-logo.svg"
                    // width={"30px"}
                    // height={"30px"}
                    className="ms-3"
                    alt="Logo"
                  />
                  <IconButton
                    onClick={handleDrawerClose}
                    style={{ color: "#000" }}
                  >
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List className="List-div">
                  <p className="items-main-heading">General Menu</p>
                  {filteredMenuItems?.map(({ text, path, disabled }, index) => (
                    <ListItem
                      key={text}
                      disablePadding
                      disabled={disabled}
                      selected={index === selectedItem}
                      className={
                        index === selectedItem
                          ? "list-item-active"
                          : "list-item"
                      }
                    >
                      <ListItemButton
                        component={Link}
                        to={path}
                        onClick={() => handleListItemClick(index)}
                        selected={index === selectedItem}
                        className={
                          index === selectedItem
                            ? "list-item-active"
                            : "list-item"
                        }
                        sx={{
                          "& .MuiListItemText-root": {
                            fontSize: "14px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        <ListItemIcon
                          minWidth={"24px"}
                          className="list-icons"
                          selected={index === selectedItem}
                          sx={{
                            "& ..MuiListItemIcon-root": {
                              minWidth: "24px",
                            },
                          }}
                        >
                          <img
                            src={dashicons[index]}
                            alt={`Icon ${index + 1}`}
                          />
                        </ListItemIcon>
                        <ListItemText
                          selected={index === selectedItem}
                          className={
                            index === selectedItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          primary={text}
                        />
                        {disabled && (
                          <img src="/Images/clock.svg" alt={`Disabled Image`} />
                        )}{" "}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Divider />

                <List className="List-div">
                  <p className="items-main-heading">Other Menu</p>

                  <div>
                    {filteredOtherMenuItems?.map(({ text, path }, index) => (
                      <ListItem
                        key={text}
                        disablePadding
                        selected={index === selectedotherItem}
                      >
                        <ListItemButton
                          component={Link}
                          onClick={() => {
                            handleOtherListItemClick(index);
                            window.open(path, "_blank");
                          }}
                          selected={index === selectedotherItem}
                          className={
                            index === selectedotherItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          sx={{
                            "& .MuiListItemText-root": {
                              fontSize: "14px",
                            },
                            "& .MuiListItemText-primary": {
                              fontSize: "14px",
                            },
                          }}
                        >
                          <ListItemIcon
                            className="list-icons"
                            sx={{
                              "& ..MuiListItemIcon-root": {
                                minWidth: "24px",
                              },
                            }}
                            minWidth={"24px"}
                          >
                            <img
                              src={otherdashicons[index]}
                              alt={`Icon ${index + 1}`}
                            />
                          </ListItemIcon>
                          <ListItemText
                            selected={index === selectedotherItem}
                            className={
                              index === selectedotherItem
                                ? "list-item-active"
                                : "list-item"
                            }
                            primary={text}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </div>
                </List>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Logout();
                  }}
                  className="Logout-div"
                >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">logout</p>
                </div>
              </Drawer>
            )}

            <Main
              open={open}
              sx={{
                backgroundColor: "var(--grey-0-primary-screen-color, #F9F9FC)",

                paddingLeft: isMobile ? "50px" : "28px",
              }}
            >
              <DrawerHeader />
              {children}
            </Main>
          </Box>
        )}
      </ThemeProvider>
      <Web3Modal show={wallet} setModalShows={SetWallet} />
    </>
  );
}
