import { useEffect } from "react";
import { connectorsByName } from "../../utils/Web3Connection/web3React/web3React";

const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
};

const useEagerConnect = () => {
  useEffect(() => {
    const connectorId =
      typeof window !== "undefined"
        ? window.localStorage.getItem("connectorId")
        : null;
    if (connectorId && connectorId !== ConnectorNames.BSC) {  
      connectorsByName[connectorId].connectEagerly();
    }
  }, []);
};

export default useEagerConnect;