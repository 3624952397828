import SideDrawer from "../../../Components/Drawer/SideDrawer";
import React from "react";
import Form from "react-bootstrap/Form";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import "./Referrals.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Toast } from "react-bootstrap";

import Dropdown from "react-bootstrap/Dropdown";
import api from "../../../services/apiInterceptor";
import { API_URL } from "../../../services/client";

export default function Referrals() {
  const [referalId, setReferalId] = useState();
  const [showToast, setShowToast] = useState(false);
  const [tableData, setTableData] = useState();
  function createData(hash, name, email, id, date) {
    return {
      hash,
      name,
      email,
      id,
      date,
    };
  }
  const rows = [
    createData("1", "Adam", "test1234@test.com", "8EeLAqSd8x", "02/03/2024"),
    createData("2", "Mark", "test1234@test.com", "M9wZaRq1zB", "02/03/2024"),
    createData(
      "3",
      "Hamilton",
      "test1234@test.com",
      "r28O49p4P5",
      "02/04/2024"
    ),
    createData("4", "John", "test1234@test.com", "hAOYMcjaSR", "02/05/2024"),
    // createData("5", 356, 16.0, 49, 3.9,24, "30 Days Left"),
  ];

  useEffect(() => {
    setReferalId(localStorage.getItem("referralId"));
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(referalId)
      .then(() => {
        setShowToast(true);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        alert("Failed to copy referral ID to clipboard");
      });
  };
  const getReferalHistory = async () => {
    try {
      const res = await api.post(`${API_URL}/api/getReferralData`, {
        referralBy: referalId,
      });
      setTableData(res?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getReferalHistory();
  }, [referalId]);

  return (
    <>
      <SideDrawer showSidebar={true}>
        <div className="referral-whole">
          <div className="referral-main">
            <p className="referral-heading">Referral Details</p>
            <div className="r-input-btn">
              <div className="w-100">
                <Form.Group>
                  <Form.Label className="inputid-label">Referral ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter referral ID"
                    className="referral-input"
                    readOnly
                    value={referalId && referalId}
                  />
                </Form.Group>
              </div>
              <div>
                <Button
                  onClick={copyToClipboard}
                  className="referral-share-btn"
                >
                  Share
                </Button>
              </div>
            </div>
          </div>
          {tableData ? (
            <div className="row-3">
              <div className="table-above-row">
                <p className="table-main-head">Referral ID History </p>
                <div className="table-top-lhs">
                  <button className="filter-btn">
                    <img src="/Images/Dashboard/filter.svg" /> Filter
                  </button>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="dropdown-week"
                      >
                        <img src="/Images/Dashboard/calendar-2.svg" /> This Week
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                  sx={{
                    minWidth: 400,
                    "& .MuiTableCell-sizeMedium": {
                      padding: "16px 24px",
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow className="columns-name">
                      <TableCell className="column-head">#</TableCell>
                      <TableCell className="column-head">Name</TableCell>
                      <TableCell className="column-head">Email</TableCell>
                      <TableCell className="column-head">ID</TableCell>
                      <TableCell className="column-head">Date </TableCell>

                      {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.hash}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {row.hash}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "160%",
                            textAlign: "start",
                            width: "500px",
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--Black-500---Primary, #1D1F2C)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "160%",
                            textAlign: "start",
                          }}
                        >
                          {row.date}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <hr className="hr-above"></hr>
                <Stack spacing={8} className="stack-contain">
                  <Pagination
                    count={4}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={2}
                  />
                </Stack>
              </TableContainer>
            </div>
          ):"no referal"}
        </div>
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000} // Auto-hide after 3 seconds
          autohide
          style={{
            position: "absolute",
            top: 80,
            right: 20,
            minWidth: 200,
          }}
        >
          <Toast.Body>Referral ID copied to clipboard!</Toast.Body>
        </Toast>
      </SideDrawer>
    </>
  );
}
