import SideDrawer from "../../Components/Drawer/SideDrawer";
import React, { useState, useEffect } from "react";
import "./claimprofits.css";
import TimerButton from "./TimerButton";
import { useDispatch, useSelector } from "react-redux";
import { getListNft } from "../../services/redux/middleware/getNft";
import ScreenLoader from "../loader/ScreenLoader";
import { getProfileInfo } from "../../services/redux/middleware/signin";

export default function ClaimProfits() {
  const [data, setData] = useState(true);
  const dispatch = useDispatch();
  const nftTable = useSelector((state) => state?.listNft);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const listNft = useSelector(
    (state) => state?.listNft?.listNft?.data?.nftData
  );
  const userDataInfo = useSelector(
    (state) => state?.profileInfo?.userProfile?.data
  );

  useEffect(() => {
    const userId = localStorage.getItem("_id");

    dispatch(getListNft(userId));
    dispatch(getProfileInfo(userId));
  }, []);

  const handleClick = () => {
  };

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = originalDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const getExpiryDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);

    const expiryDate = new Date(originalDate.getTime());
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedExpiryDate = expiryDate.toLocaleDateString("en-US", options);

    return formattedExpiryDate;
  };

  const filteredUserProfiles = listNft?.filter((row) =>
    row?.nftName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // After 5 seconds, set isLoading to false
    }, 3000);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
    {isLoading && <ScreenLoader/>}
      {nftTable?.loading && <ScreenLoader />}
      {/* <SideDrawer showSidebar={true}> */}
        <div className="ClaimProfits__main">
          <div className="ClaimProfits__main-heading_div">
            <div className="ClaimProfits__main-heading">
              <h3>Web3Fund NFT Information</h3>
              <p>
                
                <span style={{fontWeight:"500",color:"black"}}>
                Wallet Address : { " "}
                <span style={{fontWeight:"normal",letterSpacing:"0.5px",color:"#667085"}}>

                  {userDataInfo?.user?.walletAddress
                    ? `${userDataInfo?.user?.walletAddress}`
                    : " "}
                </span>
                </span>
              </p>
            </div>
            <div className="search-input_div">
              <input
                type="text"
                placeholder="Search..."
                className="search-input"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <img src="/Images/search-input.svg" alt="/" className="search-input__image" />
            </div>
          </div>

          {filteredUserProfiles && filteredUserProfiles?.length > 0 ? (
            <div className="ClaimProfits__main-content">
              {filteredUserProfiles?.map((item, index) => {
                return (
                  <div key={index} className="ClaimProfits__main-cards">
                    <img
                      src={`/Images/MIntedMonthly/${item?.monthCount + 1}.svg`}
                      alt="/"
                    />
                    <div className="ClaimProfits__main-cards-hero">
                      <div className="ClaimProfits__main-cards-heading">
                        <h6>{item?.nftName}</h6>
                        <p>
                          TOKEN ID: <span>{item?.nftId}</span>
                        </p>
                      </div>
                      <div className="ClaimProfits__main-cards-hero-card">
                        <div className="ClaimProfits__main-cards-txt">
                          <p>Mint date</p>
                          <h6>{ConvertDate(item?.txDate)}</h6>
                        </div>
                        <div className="ClaimProfits__main-cards-txt">
                          <p>Eligible for listing</p>
                          <h6>{getExpiryDate(item?.txDate)}</h6>
                        </div>
                      </div>
                      <div className="ClaimProfits__main-cards-hero-card">
                        <div className="ClaimProfits__main-cards-txt">
                          <p>Chain</p>
                          <h6>Polygon</h6>
                        </div>
                        <div className="ClaimProfits__main-cards-txt">
                          <p>Unrealised value</p>
                          <h6>{(item?.value / item?.quantity).toLocaleString()}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="ClaimProfits__main-cards-btn">
                      <TimerButton
                        buttonText="List NFT"
                        onClick={handleClick}
                        createdAt={item?.txDate}
                      />
                    </div>

                    {/* <button>Claimable</button> */}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="claimProfit__noNft-main">
              <div className="claimProfit__noNft-main-txt">
                <img src="/Images/NoNFT.svg" alt="/" />
                <h6>No NFTs Available</h6>
                <p>You currently do not have any NFT <br/> associated to your wallet</p>
              </div>
            </div>
          )}
        </div>
      {/* </SideDrawer> */}
    </>
  );
}
