import SideDrawer from "../../Components/Drawer/SideDrawer";
import "./UserProfile.css";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Button } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import UserPorfileCard from "../../Components/Cards/UserProfileCard/UserprofileCard";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AccountHistoryTable from "../../Components/AccountHistoryTable/AccountHistoryTable";
import MyReferralTable from "../../Components/MyReferralTable/MyReferralTable";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../services/redux/middleware/signin";
import { Toast } from "react-bootstrap";
import {
  getReferalData,
  getUserHistory,
} from "../../services/redux/middleware/getNft";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import { SuccessToast } from "../../Components/toast/Toast";

export default function UserProfile() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [referralID, setreferralID] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [selectedDropDown, setSelectedDropDown] = useState("All Time");
  const [tableBtn, setTableBtn] = useState(1);
  const dispatch = useDispatch();
  const userDataInfo = useSelector(
    (state) => state?.profileInfo?.userProfile?.data
  );
  const userDataInfoLoader = useSelector((state) => state?.profileInfo);

  const handleReferralCopy = async (referralLink) => {
    try {
      await navigator.clipboard.writeText(
        `https://app.web3fund.ai/id/${referralLink}`
      );
      setShowToast(true);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };
  const UserCardArray = [
    {
      ucardimg: "/Images/User Profile/dollar-icon.svg",
      ucardh: "Total Deposits",
      ucardvalue: "$250,000",
    },
    {
      ucardimg: "/Images/outward-icon.svg",
      ucardh: "Total Profits",
      ucardvalue: "$29,435.00",
    },
    {
      ucardimg: "/Images/User Profile/nft-icon-card.svg",
      ucardh: "Total NFTs",
      ucardvalue: "24",
    },
    {
      ucardimg: "/Images/User Profile/referral-icon-card.svg",
      ucardh: "Users Referred",
      ucardvalue: "938",
    },
  ];
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    const data = {
      id: userId,
      page: 1,
    };
    dispatch(getReferalData(data));
  }, []);
  useEffect(() => {
    const userId = localStorage.getItem("_id");
    const data = {
      id: userId,
      page: 1,
    };

    dispatch(getUserHistory(data));
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("_id");
    dispatch(getProfileInfo(userId));
  }, []);

  return (
    <>
      {userDataInfoLoader?.loading && <ScreenLoader />}
    

      <div className="user-head">
        <div className="profile-info-contain">
          <div className="profile-name-contain">
            <div className="prof-name">
              <div className="profile-main">
                <img
                  width={112}
                  height={112}
                  style={{ borderRadius: "100px" }}
                  src={
                    userDataInfo?.user?.profilePic
                      ? userDataInfo?.user?.profilePic
                      : "/Images/User Profile/profile-user.svg"
                  }
                  alt="Profile-img"
                />
              </div>
              <p className="user-name">
                {userDataInfo?.user?.name ? userDataInfo?.user?.name : "---"}
              </p>
            </div>
            <div className="referral-edit">
              <Button
                onClick={() => {
                  navigate("/Dashboard/Profile-Detail");
                }}
                className="user-edit-btn"
              >
                Edit Profile
              </Button>
              <div className="Referral-box">
                <p>
                  Referral Link:{" "}
                  <span>
                    {" "}
                    https://app.web3fund.ai/id/
                    {userDataInfo?.user?.referralId
                      ? userDataInfo?.user?.referralId
                      : "No refferal"}
                  </span>
                </p>{" "}
                <img
                  src="/Images/User Profile/copy-userprofile.svg"
                  alt="copy-txt"
                  className="link-copy"
                  onClick={() => {
                    handleReferralCopy(userDataInfo?.user?.referralId);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="profile-info-rhs">
          <div className="user-heading">
            <p>Email:</p>
            <p>Telegram Handle:</p>
            <p>X Handle:</p>
            {/* <p>Referrer Link:</p>
              <p>Referrer:</p> */}
            <p>Wallet Address:</p>
          </div>
          <div className="user-value">
            <p className="value-p1">
              {userDataInfo?.user?.email ? (
                userDataInfo?.user?.email
              ) : (
                <p className="empty-dash">-</p>
              )}
            </p>
            <p className="value-p1">
              {userDataInfo?.user?.telegramHandle ? (
                userDataInfo?.user?.telegramHandle
              ) : (
                <p className="empty-dash">-</p>
              )}
            </p>
            <p className="value-p1">
              {userDataInfo?.user?.xHandle ? (
                userDataInfo?.user?.xHandle
              ) : (
                <p className="empty-dash">-</p>
              )}
            </p>
            {/* <p className="value-p1">
                {userDataInfo?.user?.referralBy
                  ? ` https://www.web3fund.ai/${userDataInfo?.user?.referralBy}`
                  : <p className="empty-dash">-</p>}
              </p>

              <p className="value-p1">
                {userDataInfo?.userReferedBy
                  ? userDataInfo?.userReferedBy?.email
                  : <p className="empty-dash">-</p>}
              </p> */}
            <p className="value-p1">
              {" "}
              {userDataInfo?.user?.walletAddress ? (
                `${userDataInfo?.user?.walletAddress?.slice(
                  0,
                  8
                )}....${userDataInfo?.user?.walletAddress?.slice(-7)}`
              ) : (
                <p className="empty-dash">-</p>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="user-profile-cards">
        {/* {UserCardArray.map((card, index) => ( */}
        <UserPorfileCard
          // key={0}
          ucardimg={"/Images/User Profile/dollar-icon.svg"}
          ucardh={"Total Deposits"}
          ucardvalue={`$${userDataInfo?.totalPrice.toLocaleString()}`}
        />
        <UserPorfileCard
          // key={0}
          ucardimg={"/Images/outward-icon.svg"}
          ucardh={"Total Profits"}
          ucardvalue={`$${(
            userDataInfo?.totalValue -
            userDataInfo?.totalPrice 
          ).toLocaleString()}`}
        />
        <UserPorfileCard
          // key={0}
          ucardimg={"/Images/User Profile/nft-icon-card.svg"}
          ucardh={"Total NFTs"}
          ucardvalue={`${userDataInfo?.totalNfts}`}
        />
        <UserPorfileCard
          // key={0}
          ucardimg={"/Images/User Profile/referral-icon-card.svg"}
          ucardh={"Users Referred"}
          ucardvalue={`${userDataInfo?.userRefered}`}
        />
        {/* ))} */}
      </div>

      <div className="user-row3">
        <div className="table-above-row">
          <div className="btns-table-main">
            <Button
              className={tableBtn === 1 ? "btn-active-tb" : "btn-account-table"}
              onClick={() => {
                setTableBtn(1);
              }}
            >
              Account History
            </Button>
            <Button
              className={
                tableBtn === 0 ? "btn-active-tb" : "btn-referral-table"
              }
              onClick={() => {
                setTableBtn(0);
              }}
            >
              My Referrals
            </Button>
          </div>

          <div className="table-top-lhs">
            {/* <button className="filter-btn">
                <img src="/Images/Dashboard/filter.svg" /> Filter
              </button> */}
            <div>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="dropdown-week">
                  <img src="/Images/Dashboard/calendar-2.svg" />{" "}
                  {selectedDropDown}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("All Time");
                    }}
                  >
                    All Time
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                      onClick={() => {
                        setSelectedDropDown("This Week");
                      }}
                    >
                      This Week
                    </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("1 Year");
                    }}
                  >
                    1 Year
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("90 Days");
                    }}
                  >
                    90 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("30 Days");
                    }}
                  >
                    30 Days
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDropDown("7 Days");
                    }}
                  >
                    7 Days
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {/* My referrals */}
        {tableBtn === 0 ? (
          <MyReferralTable selectedDropDown={selectedDropDown} />
        ) : (
          <AccountHistoryTable selectedDropDown={selectedDropDown} />
        )}
      </div>
      {/* </SideDrawer> */}

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000} // Auto-hide after 3 seconds
        autohide
        style={{
          position: "absolute",
          top: 80,
          right: 20,
          minWidth: 200,
        }}
      >
        <Toast.Body>Referral ID copied to clipboard!</Toast.Body>
      </Toast>
    </>
  );
}
